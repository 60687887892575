<div id="mydecks-wrapper">
    <div id="mydecks-toolbar">
        <account-menu-button></account-menu-button>
        <div matTooltip="Verify your Email before creating decks" [matTooltipDisabled]="userActivated()? true : null">
            <button matTooltip="Create a new Deck from Scratch" [disabled]="!userActivated() || !limits.checkDeckLimit(decks?.length + 1)" mat-stroked-button id="mydecks-newbtn" (click)="newDeck()">New Deck</button>
            <button matTooltip="Upload a Deck from JSON, CSV or Text" [disabled]="!userActivated() || !limits.checkDeckLimit(decks?.length + 1)" mat-stroked-button id="mydecks-uploadbtn" (click)="uploadDeck()">Upload Deck</button>
        </div>
        <div id="deckCounter">
            {{decks?.length}}<span *ngIf="limits.deckLimit != -1"> / {{limits.deckLimit}}</span>
        </div>

    </div>

    <div id="mydecks-listwrapper">
        <empty-state *ngIf="decks != null && decks.length == 0 && !loading" topMsg="You have no decks" [btnText]="!userActivated()? 'Verify your Email before creating decks' : 'Create first Deck'" [btnTooltip]="!userActivated()? 'Verify your Email before creating decks' : ''" [btnDisabled]="!userActivated()" (btnClicked)="newDeck()"></empty-state>

        <div *ngIf="decks != null && decks.length > 0" id="mydecks-list">
            <comp-carddeck-card *ngFor="let deck of decks" [routerLink]="['../edit', deck.deckcode]" [cardDeck]="deck" class="deck-card" [showDeckState]="true" [hasCheckpoint]="hasCheckpoint(deck.deckcode)"></comp-carddeck-card>
            <comp-carddeck-card *ngFor="let checkpoint of newCheckpoints" [routerLink]="['../edit', checkpoint.id]" [cardDeck]="checkpoint.deck" class="deck-card" [showDeckState]="true"></comp-carddeck-card>
        </div>

        <div *ngIf="loading" class="spinner-overlay">
            <mat-progress-spinner
              class="spinner"
              color="#2196f3"
              mode="indeterminate">
          </mat-progress-spinner>
        </div>
    </div>
</div>