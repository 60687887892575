<div id="tasks-content">
    <h1>Running Tasks:</h1>
    <div id="runningTasks" *ngFor="let task of taskInfo.tasks">
        <h3>{{task.name}}</h3>
        <p *ngIf="task.running"><strong>Running </strong> <span *ngIf="task.nextDate == 'manual'">manual</span></p>
        <p *ngIf="!task.running"><strong>Next Schedule: </strong>{{task.nextDate | date:"dd.MM.yyyy HH:mm"}}</p>
    </div>

    <h1>Available Tasks:</h1>
    <div id="availableTasks" *ngFor="let task of taskInfo.availableTasks">
        <button mat-stroked-button (click)="startTask(task)">Start</button>
        <h3>{{task}}</h3>
    </div>
</div>