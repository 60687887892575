import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { Role } from '../Classes';
import { AccountMenuService } from '../account-menu.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  menuOpen: boolean = false;
  shadowVisible: boolean = false;

  isModerator() {
    return this.api.isLoggedIn() && this.api.user.hasRole(Role.Moderator);
  }

  constructor(private api: ApiService, private menuBtnSvc: AccountMenuService, private router: Router) { }

  closeMenu() {
    this.menuBtnSvc.toggleMenu(false);
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.onApiReady();
    });

    this.menuBtnSvc.isOpen.subscribe((isOpen) => {
      if (isOpen) {
        this.shadowVisible = true;
        setTimeout(() => {
          this.menuOpen = true;
        }, 10);
      } else {
        this.menuOpen = false;
        setTimeout(() => {
          this.shadowVisible = false;
        }, 300);
      }
    });
  }

  onApiReady() {
    if (!this.api.isLoggedIn()) this.router.navigate(['/']);
  }
}
