import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { CardDeck, BlackCard, WhiteCard } from '../Classes';
import { ApiService } from '../api.service';
import { LogindialogComponent } from '../logindialog/logindialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { showErrorSnackbar } from '../utils';

@Component({
  selector: 'app-modview',
  templateUrl: './modview.component.html',
  styleUrls: ['./modview.component.scss']
})
export class ModviewComponent implements OnInit {

  cardDeck: CardDeck = new CardDeck({});

  saving: boolean = false;
  onlyPending: boolean = true;
  nsfwState: number = 0;

  acceptCardDeck: any = null;
  acceptCardDeck2: any = null;

  stateBlacks = new Map<number, boolean>();
  stateWhites = new Map<number, boolean>();

  changedBlacks: number[] = [];
  changedWhites: number[] = [];

  constructor(private api: ApiService, public dialog: MatDialog, private _snackBar: MatSnackBar, private location: Location, private route: ActivatedRoute, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon('nsfw', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/nsfw-24px.svg"));
  }

  onlyPendingBlacks() {
    let result: BlackCard[] = [];
    this.cardDeck.blacks.forEach(black => {
      if (black.state == 2) result.push(black);
    });

    return result;
  }

  onlyPendingWhites() {
    let result: WhiteCard[] = [];
    this.cardDeck.whites.forEach(white => {
      if (white.state == 2) result.push(white);
    });

    return result;
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.onApiReady();
    });
  }

  onApiReady() {
    let deckcode = this.route.snapshot.paramMap.get('deckcode');
    console.log(deckcode);

    this.saving = true;

    this.api.getModDeck(deckcode).then((data) => {

      this.cardDeck = data;

      this.nsfwState = this.cardDeck.nsfw;

      if (this.cardDeck.state != 2) this.acceptCardDeck = this.cardDeck.state == 1;
      if (this.cardDeck.state != 2) this.acceptCardDeck2 = this.cardDeck.state == 1? "true" : "false";

      this.cardDeck.blacks.forEach(black => {
        if (black.botstate == 0 && black.state != 0) {
          this.stateBlacks.set(black.id, false);
          this.changedBlacks.push(black.id);
        }
        if (black.state != 2) this.stateBlacks.set(black.id, black.state == 1);
      });

      this.cardDeck.whites.forEach(white => {
        if (white.botstate == 0 && white.state != 0) {
          this.stateWhites.set(white.id, false);
          this.changedWhites.push(white.id);
        }
        if (white.state != 2) this.stateWhites.set(white.id, white.state == 1);
      });

      if (this.cardDeck.moderationLock) {
        const dialogRef = this.dialog.open(ModLockDialog, {
          width: '250px',
          data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) this.location.back();
        });
      }

      this.saving = false;

    }).catch((err) => {
      const dialogRef = this.dialog.open(ErrorDialog, {
        width: '250px',
        data: err
      });

      dialogRef.afterClosed().subscribe(result => {
        this.location.back();
        this.saving = false;
      });
    });
  }

  btnCancelClick() {
    this.api.moderateCardDeck(this.cardDeck.deckcode, null, null, null, null, null).then((data) => {
      this.location.back()
    }).catch((err) => {
      this._snackBar.open("Could not remove Moderation Lock: " + err.error.message, null, {duration: 2000});
    });
  }

  btnSaveClick() {
    this.saving = true;

    let reportBlacks = new Map<number, boolean>();
    this.changedBlacks.forEach(bid => {
      reportBlacks.set(bid, this.stateBlacks.get(bid));
    });

    let reportWhites = new Map<number, boolean>();
    this.changedWhites.forEach(wid => {
      reportWhites.set(wid, this.stateWhites.get(wid));
    });

    this.api.moderateCardDeck(this.cardDeck.deckcode, this.cardDeck.moderationNotes, reportBlacks, reportWhites, this.acceptCardDeck, this.nsfwState).then((data) => {
      this.location.back()
    }).catch((err) => {
      if (err.status == 401) {
        this.relogin().then((data) => {
          this.btnSaveClick();
        }).catch((err) => {
          this.saving = false;
        });
      } else {
        console.log(err);
        showErrorSnackbar(err, this._snackBar);
      }
    });
  }

  deckAcceptDecline(event) {
    this.acceptCardDeck = event.value == "true";
  }

  cardAcceptDeclineWhite(data) {
    if (this.stateWhites.get(data.id) == data.accepted) {
      this.stateWhites.delete(data.id);
      this.changedWhites = this.changedWhites.filter(v => v !== data.id);
    } else {
      this.stateWhites.set(data.id, data.accepted);
      this.changedWhites.push(data.id);
    }
  }

  cardAcceptDeclineBlack(data) {
    if (this.stateBlacks.get(data.id) == data.accepted) {
      this.stateBlacks.delete(data.id);
      this.changedBlacks = this.changedBlacks.filter(v => v !== data.id);
    } else {
      this.stateBlacks.set(data.id, data.accepted);
      this.changedBlacks.push(data.id);
    }
  }

  acceptPending() {
    this.cardDeck.blacks.forEach(black => {
      if (this.stateBlacks.get(black.id) == undefined) {
        this.stateBlacks.set(black.id, true);
        this.changedBlacks.push(black.id);
      }
    });

    this.cardDeck.whites.forEach(white => {
      if (this.stateWhites.get(white.id) == undefined) {
        this.stateWhites.set(white.id, true);
        this.changedWhites.push(white.id);
      }
    });
  }

  relogin() {
    return new Promise<void>((resolve, reject) => {
      const dialogRef = this.dialog.open(LogindialogComponent, {
        width: '700px',
        data: { username: this.api.user.username }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          reject();
          return;
        }

        this.api.getToken(result.username, result.password).then((data) => {
          resolve();
        }).catch((reason) => {
          this._snackBar.open("There has been an Error logging you back in", null, {duration: 2000});
          reject();
        });
      });
    });
  }


}


@Component({
  selector: 'error-dialog',
  templateUrl: 'errordialog.html',
})
export class ErrorDialog {

  constructor(
    public dialogRef: MatDialogRef<ErrorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'modlock-dialog',
  templateUrl: 'modlockdialog.html',
})
export class ModLockDialog {

  constructor(
    public dialogRef: MatDialogRef<ErrorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

}