import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";

export function showErrorSnackbar(error: HttpErrorResponse, snackBar: MatSnackBar) {
    console.log(error);

    let message = "";

    if (error.error != undefined && error.error.message != undefined) {
        message = error.error.message;
    } else {
        message = error.status + " " + error.statusText;
    }

    let traceid = undefined;
    if (error.headers != undefined) {
        traceid = error.headers.get("x-traceid");
    }

    let snackBarRef = snackBar.open(message, traceid == null? null : "Copy Trace", {
        duration: 2000,
    });

    if (traceid != null) {
        snackBarRef.onAction().subscribe(() => {
            console.log("Copying traceid to clipboard: " + traceid);
            navigator.clipboard.writeText(traceid);
        });
    }
}