import { BlackCard, CardDeck, languages, WhiteCard } from "./Classes";

export function parseDeck(data: string | string[][]): CardDeck {
    console.log(typeof data)
    if (typeof data == "string") {

        //console.log(sanitize(data, false));

        // Try if JSON Parsing works
        var json = null;
        try {
            json = JSON.parse(sanitize(data));
        } catch (e) {
            throw e;
            // Did not work
            console.log("JSON did not work!");
            json = null;
        }

        // If Json Parsing did work parse the Deck
        if (json != null) {
            return parseJson(json);
        }
        
        // Else try to determine if it is a CSV String
        /* var csv = CSVToArray(sanitize(data, false), ";");

        if (csv[0].length < 2) {
            csv = CSVToArray(sanitize(data, false), ",");
        }

        console.log(csv); */
    }

    throw new Error("This Data Format is not supported");
}

function sanitize(text: string, doubleQuote: boolean = true): string {
    return text.replace(/&amp;/g, "&").replace(/&gt;/g, ">").replace(/&lt;/g, "<").replace(/&apos;/g, "'").replace(/&quot;/g, doubleQuote? "\\\"" : "\"");
}

function parseJson(newDeck: any): CardDeck {

    var name = "";
    var desc = "";
    var priv = false;
    var nsfw = false;
    var language = languages[0];
    var blacks = [];
    var whites = [];

    if (newDeck.hasOwnProperty('language') && newDeck.hasOwnProperty('name') && newDeck.hasOwnProperty('description') && newDeck.hasOwnProperty('blacks') && newDeck.hasOwnProperty('whites')) {
      // CRCast Exporter
      name = newDeck.name;
      desc = newDeck.description;
      priv = newDeck.private;
      nsfw = newDeck.nsfw;
      language = languages.find(l => l.langCode == newDeck.language);

      console.log(newDeck.language);
      console.log(languages.find(l => l.langCode == newDeck.language));
      console.log(languages);

      newDeck.blacks.forEach(black => {
        let newBlack = new BlackCard();
        newBlack.text = black.join("_");
        blacks.push(newBlack);
      });

      newDeck.whites.forEach(white => {
        let newWhite = new WhiteCard();
        newWhite.text = white[0];
        whites.push(newWhite);
      });
    } else if (newDeck.hasOwnProperty('pack') && newDeck.pack.hasOwnProperty('pack') && newDeck.pack.hasOwnProperty('black') && newDeck.pack.hasOwnProperty('white')) {
      // All Bad Cards Cardcast Exporter
      name = newDeck.pack.pack.name;

      newDeck.pack.black.forEach(black => {
        let newBlack = new BlackCard();
        newBlack.text = black.content;
        blacks.push(newBlack);
      });

      newDeck.pack.white.forEach(white => {
        let newWhite = new WhiteCard();
        newWhite.text = white;
        whites.push(newWhite);
      });
    } else if (newDeck.hasOwnProperty('packName') && newDeck.hasOwnProperty('whiteCards') && newDeck.hasOwnProperty('blackCards')) {
      //All Bad Cards Custom Card Deck Exporter
      name = newDeck.packName;
      if (newDeck.hasOwnProperty('isPublic')) priv = !newDeck.isPublic;

      newDeck.blackCards.forEach(black => {
        let newBlack = new BlackCard();
        newBlack.text = black;
        blacks.push(newBlack);
      });

      newDeck.whiteCards.forEach(white => {
        let newWhite = new WhiteCard();
        newWhite.text = white;
        whites.push(newWhite);
      });
    } else if (newDeck.hasOwnProperty('calls') && newDeck.hasOwnProperty('responses')) {
      //PYX App Custom Card Deck Exporter and Many Decks (Massive Decks) Exporter
      name = newDeck.name;
      if (newDeck.hasOwnProperty('description')) desc = newDeck.description;

      newDeck.calls.forEach(black => {
        let newBlack = new BlackCard();
        if (Array.isArray(black)) {
          var t = black[0].map(b => {
            if (typeof b == "object") return "";
            else return b;
          });
          newBlack.text = t.join("_");
        } else {
          newBlack.text = black.text.join("_");
        }
        blacks.push(newBlack);
      });

      newDeck.responses.forEach(white => {
        let newWhite = new WhiteCard();
        if (typeof white == "string") {
          newWhite.text = white;
        } else {
          newWhite.text = white.text[0];
        }
        whites.push(newWhite);
      });
    } else {
        throw new Error("This JSON Format is not supported yet");
    }
    //More JSON Formats will come in the Future

    var editCardDeck = new CardDeck();

    editCardDeck.name = name;
    editCardDeck.description = desc;
    editCardDeck.private = priv;
    editCardDeck.nsfw = nsfw? 1 : 0;
    editCardDeck.language = language;
    editCardDeck.blacks = blacks;
    editCardDeck.whites = whites;

    return editCardDeck;
}

function CSVToArray( strData, strDelimiter ){
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = (strDelimiter || ",");

    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
        (
            // Delimiters.
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

            // Quoted fields.
            "(" + //?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

            // Standard fields.
            "([^\\" + strDelimiter + "\\r\\n]*))" //"([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
        "gi"
        );


    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData = [[]];

    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null;


    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while (arrMatches = objPattern.exec( strData )){

        // Get the delimiter that was found.
        var strMatchedDelimiter = arrMatches[ 1 ];

        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (
            strMatchedDelimiter.length &&
            strMatchedDelimiter !== strDelimiter
            ){

            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push( [] );

        }

        var strMatchedValue;

        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[ 2 ]){

            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            strMatchedValue = arrMatches[ 2 ].replace(
                new RegExp( "\"\"", "g" ),
                "\""
                );

        } else {

            // We found a non-quoted value.
            strMatchedValue = arrMatches[ 3 ];

        }


        // Now that we have our value string, let's add
        // it to the data array.
        arrData[ arrData.length - 1 ].push( strMatchedValue );
    }

    // Return the parsed data.
    return( arrData );
}