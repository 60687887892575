<div id="content">
	<div id="info-container">
		<div id="info-left" class="info-element notmobile">
			<p class="version">V0.5.1 b2364</p>
			<p class="version">API: {{apiVersion.version + " b" + apiVersion.build}}</p>
			<div *ngIf="apiVersion.branch != 'master' && apiVersion.branch != '%BRANCH%'" class="version">
				{{apiVersion.branch}}<br/>
				{{apiVersion.commit}}
			</div>
		</div>
		<div id="info-left" class="info-element mobile">

		</div>
		<div id="info-center" class="info-element">
			<div id="center-info-title">
				Welcome to CRCast
			</div>
		</div>
		<div id="info-right" class="info-element notmobile">
		</div>
	</div>
	<div id="categories">

		<app-categorylist title="Featured" sort="featured"></app-categorylist>
		<app-categorylist title="Popular Last 30 Days" sort="popularLM"></app-categorylist>
		<app-categorylist title="Popular All Time" sort="popular"></app-categorylist>
		<app-categorylist title="Newest" sort="new"></app-categorylist>

	</div>
</div>

<div id="footer_container">
	<div id="footer" class="notmobile">
		<div class="footer-content">
			This website is not affiliated with Cards Against Humanity!
		</div>
		<div class="footer-content">
			<a [routerLink]="['/legal']">Privacy, Copyright and Legal disclaimers</a>
		</div>
		<div class="footer-content">
			<a href="https://discord.gg/85GBhxd" rel="noopener noreferrer" target="_blank">Discord,</a> &nbsp;
			<a href="https://www.reddit.com/r/CLRTD/" rel="noopener noreferrer" target="_blank">Subreddit,</a>&nbsp;
			<a href="https://twitter.com/sirjuicebag" rel="noopener noreferrer" target="_blank">Twitter</a>
		</div>
		<div class="footer-content">
			Copyright © 2020 - 2023 CLRTD
		</div>
	</div>
	<div id="footer_mobile" class="mobile">
		<div class="footer_mobile_content" id="footer_affiliate">
			This website is not affiliated with Cards Against Humanity!
		</div>
		<div class="footer_mobile_content" id="footer_legal">
			<a [routerLink]="['/legal']">Privacy, Copyright and Legal disclaimers</a>
		</div>
		<div class="footer_mobile_content" id="footer_social">
			<a href="https://discord.gg/85GBhxd" rel="noopener noreferrer" target="_blank">Discord,</a> &nbsp;
			<a href="https://www.reddit.com/r/CLRTD/" rel="noopener noreferrer" target="_blank">Subreddit,</a>&nbsp;
			<a href="https://twitter.com/sirjuicebag" rel="noopener noreferrer" target="_blank">Twitter</a>
		</div>
		<div class="footer_mobile_content" id="footer_copyright">
			Copyright © 2020 - 2023 CLRTD
		</div>
	</div>
</div>