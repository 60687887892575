<div *ngIf="user() != null" id="profile-content">
  <div id="topBar">
    <account-menu-button></account-menu-button>
  </div>

  <div id="profile-wrapper">
    <mat-card id="profile-card">
      <mat-card-header>
        <mat-card-title>Profile</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <span><strong>Username: </strong>{{user().username}}</span><br/>
        <span><strong>E-Mail: </strong>{{user().email}}</span><br/>
        <span *ngIf="user().hasRole(Role().Moderator)"><strong>Moderator: </strong>{{user().hasRole(Role().Moderator)? "Yes" : "No"}}</span><br/>
        <span><strong>Email verified: </strong>{{user().activated? "Yes" : "No"}}</span>
      </mat-card-content>
      <mat-card-actions>
        <button *ngIf="user().deletionQueued == null" mat-flat-button color="warn" (click)="openDeleteDialog()">Delete</button>
        <button mat-button (click)="openEmailChangeDialog()">Change E-Mail</button>
      </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="!user().activated" id="activation-card">
      <mat-card-header>
        <mat-card-title>Account not activated</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          Your Account has not been activated yet
        </p>
        <p>
          If you want to resend the Activation E-Mail press the Button below.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="resendMail()">Resend E-Mail</button>
      </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="user().deletionQueued != null" id="deletionnotice-card">
      <mat-card-header>
        <mat-card-title>Account Deletion Queued</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          Your Account has been queued for Deletion!
        </p>

        <blockquote>{{deletionDate() | date}}</blockquote>
        <p>

          If you want to keep your Account press the Cancel Button below before the Date shown above.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="cancelDeletion()">CANCEL</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>