<div class="blackcard">

    <div *ngIf="!newCard && !editingCard" class="blackcard-text">
        <div *ngIf="!isImage(blackCard.text)">{{blackCard.text}}</div>
        <img alt="Black Card Image" *ngIf="isImage(blackCard.text)" class="blackcard-image" src="{{getImageURL(blackCard.text)}}"/>
    </div>

    <mat-form-field *ngIf="newCard || editingCard" appearance="standard" class="blackcard-textinput">
        <textarea matInput #message maxlength="150" placeholder="Epic Black Card" [(ngModel)]="blackCard.text"></textarea>
        <mat-hint align="end">{{message.value.length}} / 150</mat-hint>
        <mat-hint align="start">PICK {{blackCard.getPick()}}</mat-hint>
    </mat-form-field>

    <div *ngIf="!newCard && !editingCard" class="blackcard-pick"><div class="blackcards-counttext">PICK {{blackCard.getPick()}}</div></div>
    <div *ngIf="!newCard && !editingCard && false" class="blackcard-draw"><div class="blackcards-counttext">DRAW {{blackCard.getPick()}}</div></div>

    <div *ngIf="_isChanged()" class="changedMarker"></div>

    <div class="blackcard-menu" [style.background]="_stateBackground()" [matTooltip]="_getCardWarning(blackCard.text)" [matTooltipDisabled]="_getCardWarning(blackCard.text) == ''">
        <button *ngIf="_showEditButtons() && !editingCard" mat-icon-button aria-label="Delete" (click)="btnDeleteClick()" style="color: #d50000;">
            <mat-icon>delete</mat-icon>
        </button>

        <button *ngIf="_showEditButtons() && !editingCard" mat-icon-button aria-label="Edit" (click)="btnEditClick()">
            <mat-icon>edit</mat-icon>
        </button>

        <button *ngIf="newCard" mat-icon-button aria-label="Add" (click)="btnAddClick()" [disabled]="blackCard.text.trim() == ''">
            <mat-icon>add</mat-icon>
        </button>

        <button *ngIf="editingCard" mat-icon-button aria-label="Save" (click)="btnSaveClick()" [disabled]="blackCard.text.trim() == ''">
            <mat-icon>save</mat-icon>
        </button>

        <button *ngIf="editingCard" mat-icon-button aria-label="Cancel" (click)="btnCancelClick()">
            <mat-icon>cancel</mat-icon>
        </button>

        <button *ngIf="showModButtons" [style.background]="(accepted && accepted != undefined)? 'rgba(0,200,83, 0.3)' : 'transparent'" style="color: #00c853;" mat-icon-button aria-label="Accept" (click)="btnModClicked(true)">
            <mat-icon>done</mat-icon>
        </button>

        <button *ngIf="showModButtons" [style.background]="(!accepted && accepted != undefined)? 'rgba(213,0,0, 0.3)' : 'transparent'" style="color: #d50000;" mat-icon-button aria-label="Decline" (click)="btnModClicked(false)">
            <mat-icon>close</mat-icon>
        </button>

        <div *ngIf="_isUnpublished()" id="unpublished-text">
            Unpublished
        </div>
    </div>
</div>