import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Role } from '../Classes';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {

  isModerator() {
    return this.api.isLoggedIn() && this.api.user.hasRole(Role.Moderator);
  }

  constructor(private api: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.onApiReady();
    });
  }

  onApiReady() {
    if (!this.api.isLoggedIn()) this.router.navigate(['/']);
  }
}
