import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountMyDecksComponent } from './account-my-decks/account-my-decks.component';
import { AccountProfileComponent } from './account-profile/account-profile.component';
import { AccountComponent } from './account/account.component';
import { ActivatorComponent } from './activator/activator.component';
import { AdminDecklistComponent } from './admin-decklist/admin-decklist.component';
import { AdminEventlistComponent } from './admin-eventlist/admin-eventlist.component';
import { AdminTasksComponent } from './admin-tasks/admin-tasks.component';
import { AdminUserlistComponent } from './admin-userlist/admin-userlist.component';
import { AdministrationComponent } from './administration/administration.component';
import { ApidocComponent } from './apidoc/apidoc.component';
import { AuthGuardService } from './auth-guard.service';
import { ChangelogComponent } from './changelog/changelog.component';
import { DeckcreatorComponent } from './deckcreator/deckcreator.component';
import { DecklistComponent } from './decklist/decklist.component';
import { DeckviewComponent } from './deckview/deckview.component';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './home/home.component';
import { LegalComponent } from './legal/legal.component';
import { ModlistComponent } from './modlist/modlist.component';
import { ModviewComponent } from './modview/modview.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { RegisterComponent } from './register/register.component';
import { RequestResetPWComponent } from './request-reset-pw/request-reset-pw.component';
import { ResetPWComponent } from './reset-pw/reset-pw.component';


const routes: Routes = [
  { path: "", component: HomeComponent},

  { path: "register", component: RegisterComponent},
  { path: "activate", component: ActivatorComponent},
  { path: "requestReset", component: RequestResetPWComponent},
  { path: "reset", component: ResetPWComponent},

  { path: "legal", component: LegalComponent},
  { path: "api", component: ApidocComponent},
  { path: "help", component: HelpComponent},
  { path: "changelog", component: ChangelogComponent},

  { path: "deck/:deckcode", component: DeckviewComponent},
  { path: "decks", component: DecklistComponent},
  { path: "account", component: AccountComponent, canActivate: [AuthGuardService], canActivateChild: [AuthGuardService], children: [
    {path: "", redirectTo: "mydecks", pathMatch: "full"},
    {path: "mydecks", component: AccountMyDecksComponent},
    {path: "edit/:deckcode", component: DeckcreatorComponent},
    {path: "mod/:deckcode", component: ModviewComponent},
    {path: "mod", component: ModlistComponent},
    {path: "profile", component: AccountProfileComponent},
  ]},

  {path: "mod/:deckcode", component: ModviewComponent},
  {path: "mod", component: ModlistComponent},

  { path: "admin", component: AdministrationComponent, canActivate: [AuthGuardService], children: [
    {path: "", redirectTo: "decks", pathMatch: "full"},
    {path: "decks", component: AdminDecklistComponent},
    {path: "users", component: AdminUserlistComponent},
    {path: "events", component: AdminEventlistComponent},
    {path: "tasks", component: AdminTasksComponent}
  ]},
  { path: "**", component: NotfoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
