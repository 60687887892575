import { Component, OnInit, ViewChild } from '@angular/core';
import { CardDeck } from '../Classes';
import { ApiService } from '../api.service';
import { MatDialog } from '@angular/material/dialog';
import { JsonUploadDialogComponent } from '../json-upload-dialog/json-upload-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogindialogComponent } from '../logindialog/logindialog.component';
import { DeckcreatorComponent } from '../deckcreator/deckcreator.component';
import { parseDeck } from '../deckParser';
import { Router } from '@angular/router';
import { showErrorSnackbar } from '../utils';

@Component({
  selector: 'app-account-my-decks',
  templateUrl: './account-my-decks.component.html',
  styleUrls: ['./account-my-decks.component.scss']
})
export class AccountMyDecksComponent implements OnInit {
  @ViewChild(DeckcreatorComponent) deckCreator;

  decks: CardDeck[] = null;
  newCheckpoints: any[] = [];
  oldCheckpoints: any[] = [];
  maxlength: number = 100;

  loading: boolean = false;

  constructor(private api: ApiService, private router: Router, public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  get limits() { return this.api.limits; }

  decksLength() {
    if (this.decks == undefined) return 0;
    else return this.decks.length;
  }

  userActivated() {
    return this.api.isLoggedIn()? this.api.user.activated : false;
  }

  getTooltip() {
    return this.userActivated? "" : "You haven't verified your E-Mail Address"
  }

  hasCheckpoint(deckcode: string) {
    return this.oldCheckpoints.findIndex((v) => v.deck.deckcode == deckcode) != -1;
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.onApiReady();
    });
  }

  onApiReady() {
    this.getDecks();
    this.getCheckpoints();
  }

  getDecks() {
    this.loading = true;
    this.api.getUserDecks().then((data) => {
      this.decks = data;
      this.loading = false;
    }).catch((err) => {
      if (err.status == 401) {
        this.relogin().then((data) => {
          this.getDecks();
        }).catch((err) => {
          this.loading = false;
        });
      } else {
        this.loading = false;
        showErrorSnackbar(err, this._snackBar);
      }
    });
  }

  getCheckpoints() {
    let index = 0;
    while (true) {
      let key = localStorage.key(index);
      if (key == null) break;

      if (key.startsWith("checkpoint_")) {
        try {
          let check = localStorage.getItem(key);

          if (check != null) {
            let checkpointIndex = parseInt(key.substring(11));
            let saveData = JSON.parse(check);
            let checkpoint = {id: checkpointIndex, deck: new CardDeck(saveData.deck), changeBlack: saveData.changeBlack, changeWhite: saveData.changeWhite};

            if (checkpoint.deck.deckcode.length == 5) {
              this.oldCheckpoints.push(checkpoint);
            } else {
              this.newCheckpoints.push(checkpoint);
            }
          }
        } catch (e) {

        }
      }

      index++;
    }
  }

  newDeck() {
    if (!this.userActivated()) return;

    this.router.navigateByUrl('account/edit/new');
  }

  relogin() {
    return new Promise<void>((resolve, reject) => {
      const dialogRef = this.dialog.open(LogindialogComponent, {
        width: '700px',
        data: { username: this.api.user.username }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          reject();
          return;
        }

        this.api.getToken(result.username, result.password).then((data) => {
          resolve();
        }).catch((err) => {
          this._snackBar.open("There has been an Error logging you back in", null, {duration: 2000});
          reject();
        });
      });
    });
  }

  uploadDeck(): void {
    if (!this.userActivated()) return;

    const dialogRef = this.dialog.open(JsonUploadDialogComponent, {
      width: '700px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined)
        return;

      try {
        this.loading = true;

        let cardDeck = parseDeck(result);

        console.log(cardDeck);

        // Filter empty cards and trim text on others
        cardDeck.blacks.map((v) => {
          if (v.text.trim().length != 0) {
            v.text = v.text.trim();
            return v;
          }

          return null;
        });

        cardDeck.whites.map((v) => {
          if (v.text.trim().length != 0) {
            v.text = v.text.trim();
            return v;
          }

          return null;
        });

        // Check Card Limits
        if (!this.api.limits.checkBlackLimit(cardDeck.blacks.length)) {
          this._snackBar.open("The Deck has too many black cards (" + cardDeck.blacks.length + "/" + this.api.limits.blacksLimit + ")", null, {duration: 2000});
          this.loading = false;
          return;
        }

        if (!this.api.limits.checkWhiteLimit(cardDeck.whites.length)) {
          this._snackBar.open("The Deck has too many white cards (" + cardDeck.whites.length + "/" + this.api.limits.whitesLimit + ")", null, {duration: 2000});
          this.loading = false;
          return;
        }

        // Upload Deck
        this.api.newCardDeck(cardDeck, false, true).then((data) => {
          this.loading = false;
        }).catch((err) => {
          console.log("error");
          console.log(err);
          if (err.status == 401) {
            this._snackBar.open("You are logged out! Please log back in and try again", null, {duration: 2000});
            this.loading = false;
          } else {
            showErrorSnackbar(err, this._snackBar);
            this.loading = false;
          }
        });

      } catch (Exception) {
        console.log(Exception);
        this.loading = false;
        this._snackBar.open(Exception.message, null, {duration: 2000});
      }
    });
  }

}
