<div id="eventlist-content">
    <div id="eventlist-filterbar">
        <div id="filterbar-left">
            <mat-form-field appearance="outline" id="eventlist-searchbox" class="filter-field">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Search" [(ngModel)]="filtertext" [ngModelOptions]="{standalone: true}" (keydown.enter)="applyFilters()">
                <mat-icon class="noselect" matSuffix>title</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" id="eventlist-typefilter" class="filter-field">
                <mat-label>Event Types</mat-label>
                <mat-select [(value)]="filterTypes" (selectionChange)="typeFilterChanged()" multiple>
                    <mat-select-trigger>
                        <span class="text noselect">{{filterTypes.join(", ")}}</span>
                    </mat-select-trigger>

                    <mat-option value="deckused">Deckused</mat-option>
                    <mat-option value="moderation">Moderation</mat-option>
                    <mat-option value="change">Change</mat-option>

                    <mat-option value="user_changepw">Change: User Password</mat-option>
                    <mat-option value="user_changemail">Change: User E-Mail</mat-option>
                    <mat-option value="user_delete">Change: User Deletion</mat-option>
                    <mat-option value="user_canceldelete">Change: User Cancel Deletion</mat-option>
                    <mat-option value="admin_active_">Change: Admin User Active</mat-option>
                    <mat-option value="admin_ban_">Change: Admin User Banned</mat-option>
                    <mat-option value="admin_delete_">Change: Admin User Deletion</mat-option>
                    <mat-option value="admin_canceldelete_">Change: Admin Cancel Deletion</mat-option>
                    <mat-option value="admin_email_">Change: Admin User E-Mail</mat-option>
                    <mat-option value="admin_role_">Change: Admin User Role</mat-option>
                    <mat-option value="admin_password_">Change: Admin User Password</mat-option>
                    <mat-option value="deck_delete">Change: Deck Delete</mat-option>
                    <mat-option value="admin_featured_">Change: Admin Deck Featured</mat-option>
                    <mat-option value="admin_locked_">Change: Admin Deck Locked</mat-option>
                    <mat-option value="admin_nsfw_">Change: Admin Deck NSFW</mat-option>
                    <mat-option value="admin_language_">Change: Admin Deck Language</mat-option>

                </mat-select>
            </mat-form-field>
        </div>

        <div id="filterbar-right">
            <button mat-icon-button aria-label="Filter" (click)="applyFilters()">
                <mat-icon>filter_alt</mat-icon>
            </button>

            <button mat-icon-button aria-label="Clear" [disabled]="!isfilter" (click)="clearFilters()">
                <mat-icon>clear</mat-icon>
            </button>
        </div>

    </div>
    <div id="eventlist-listwrapper">
        <empty-state *ngIf="events != null && events.length == 0 && !loading" topMsg="No Events found" btnText="Clear Filters" (btnClicked)="clearFilters()"></empty-state>
        <div class="event-item" *ngFor="let event of events">
            <div class="date">
                {{event.date | date}}
            </div>
            <div class="description">
                {{eventDescription(event)}}
            </div>
        </div>
    </div>
    <mat-paginator id="paginator" [length]="eventCount"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
            [pageIndex] = "currentPage"
            (page)="switchPage($event); loadNew();"
            aria-label="Select page">
    </mat-paginator>

    <div *ngIf="loading" class="spinner-overlay">
        <mat-progress-spinner
            class="spinner"
            color="#2196f3"
            mode="indeterminate">
        </mat-progress-spinner>
    </div>
</div>
