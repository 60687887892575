import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sanitize',
})
export class TextSanitizePipe implements PipeTransform {
  transform(value: any): any {
    let text = value;

    text = text.replace(/&/g, '&amp;');
    text = text.replace(/</g, '&lt;');
    text = text.replace(/>/g, '&gt;');
    text = text.replace(/"/g, '&quot;');
    text = text.replace(/'/g, '&#039;');

    return text;
  }
}