import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable} from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private cookieService: CookieService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
          setHeaders: {
            'Content-Type' : 'application/json; charset=utf-8',
            'Accept'       : 'application/json',
            'Authorization': `Bearer ${this.cookieService.get("token")}`,
          },
        });

        return next.handle(req);

/*         .pipe(
          catchError((error: HttpErrorResponse) => {
            var errret: any = error;
            errret.body = error.error;
            return of<any>(new HttpResponse(errret));
          })
        ); */
    }
}