<div id="rpw-wrapper">
    <div id="rpw-title">
        Request Password Reset Link
    </div>

    <div *ngIf="requesting" id="spinner-overlay">
        <mat-progress-spinner
        id="spinner"
        color="#2196f3"
        mode="indeterminate">
        </mat-progress-spinner>
    </div>

    <div *ngIf="!completed" id="rpw-notcomplete">
        <div id="rpw-complete-text">
            Enter a new Password: <br/>
        </div>

        <mat-form-field appearance="outline" class="rpw-pw">
            <mat-label>New Password</mat-label>
            <input matInput type="password" placeholder="New Password" [disabled]="requesting || completed" [formControl]="password1" required>
            <mat-icon matSuffix>vpn_key</mat-icon>
            <mat-error *ngIf="password1.invalid">{{getErrorMessage("password1")}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="rpw-pw">
            <mat-label>Confirm New Password</mat-label>
            <input matInput type="password" placeholder="Confirm New Password" [disabled]="requesting || completed" [formControl]="password2" required>
            <mat-icon matSuffix>vpn_key</mat-icon>
            <mat-error *ngIf="password2.invalid">{{getErrorMessage("password2")}}</mat-error>
        </mat-form-field>

        <button id="rpw-request-button" mat-flat-button (click)="sendRequest()" [disabled]="password1.invalid || password2.invalid">Reset Password</button>
    </div>

    <div *ngIf="completed" id="rpw-complete">
        <div id="rpw-complete-text">
            Your Password has been reset.
        </div>

        <div id="rpw-complete-text2">
            If there are any Problems contact us via <a href="https://twitter.com/sirjuicebag" target="_blank" rel="noopener noreferrer" class="rpw-contactlink">Twitter</a>, <a href="https://discord.gg/85GBhxd" target="_blank" rel="noopener noreferrer" class="rpw-contactlink">Discord</a> or <a href="https://www.reddit.com/r/CLRTD/" target="_blank" rel="noopener noreferrer" class="rpw-contactlink">Reddit</a>
        </div>

        <button id="rpw-complete-button" mat-flat-button (click)="backToHome()">Back to Home</button>
    </div>
</div>
