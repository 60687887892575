<div id="decklist-content">
    <div id="decklist-filterbar" [ngClass]="{'expanded': showFilters}">
        <mat-form-field appearance="outline" id="decklist-searchbox" class="filter-field">
            <mat-label>Search</mat-label>
            <input matInput placeholder="Name or Code" [(ngModel)]="filtertext" [ngModelOptions]="{standalone: true}" (keydown.enter)="applyFilters()">
            <mat-icon class="noselect" matSuffix>title</mat-icon>
        </mat-form-field>

        <button id="expand-btn" mat-icon-button aria-label="Filter" (click)="showFilters = !showFilters">
            <mat-icon>{{showFilters? "expand_less" : "expand_more"}}</mat-icon>
        </button>

        <mat-form-field appearance="outline" id="decklist-language" class="filter-field">
            <mat-label>Language</mat-label>
            <mat-select [(ngModel)]="filterLanguage" multiple>
                <mat-option *ngFor="let lang of langs()" [value]="lang.langCode">{{lang.displayName}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-slide-toggle id="decklist-sfw" class="filter-field" [(ngModel)]="filterSFW" (change)="applyFilters()">Only SFW</mat-slide-toggle>

        <mat-slide-toggle *ngIf="isLoggedIn()" id="decklist-favorites" class="filter-field" [(ngModel)]="filterFavorites" (change)="applyFilters()">Only Favorites</mat-slide-toggle>

        <div id="spacer"></div>

        <div id="filterbar-right">
            <button mat-icon-button [disableRipple]="true" aria-label="Sort" style="width: 100px; margin-right: 69px;" (click)="selectSort.open()">
                <mat-icon>sort</mat-icon>

                <mat-select #selectSort [disableRipple]="true" class="selectSort" [(ngModel)]="sort" (selectionChange)="resort($event)">
                    <mat-option value="none">None</mat-option>
                    <mat-option value="featured">Featured</mat-option>
                    <mat-option value="popularLM">Popular Last Month</mat-option>
                    <mat-option value="popular">Popular All Time</mat-option>
                    <mat-option value="new">Newest</mat-option>
                </mat-select>
            </button>

            <button mat-icon-button aria-label="Filter" (click)="applyFilters()">
                <mat-icon>filter_alt</mat-icon>
            </button>

            <button mat-icon-button aria-label="Clear" [disabled]="!isfilter" (click)="clearFilters()">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <div id="decklist-listwrapper" #list [ngClass]="{'expanded': showFilters}">
        <empty-state *ngIf="decks != null && decks.length == 0 && !loading" topMsg="No Decks found" btnText="Clear Filters" (btnClicked)="clearFilters()"></empty-state>

        <div *ngIf="decks != null && decks.length > 0" id="decklist-list">
            <comp-carddeck-card *ngFor="let deck of decks" [cardDeck]="deck" [showDeckState]="false" [showCopyButtons]="true" [showFavButton]="true" [routerLink]="['/deck', deck.deckcode]" [searchTerm]="filtertext" (favoriteClicked)="favoriteClicked(deck)" [isFavorite]="isFavorite(deck.deckcode)" [isLoadingFav]="isLoadingFav(deck.deckcode)"></comp-carddeck-card>
        </div>

        <div #anchor style="width: 100%; height: 200px; margin-top: 64px;">
            <div *ngIf="loading" class="spinner-overlay">
                <mat-progress-spinner
                    class="spinner"
                    color="#2196f3"
                    mode="indeterminate">
                </mat-progress-spinner>
            </div>
        </div>
    </div>
</div>
