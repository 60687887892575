import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Validators, UntypedFormBuilder, AbstractControl, UntypedFormControl } from '@angular/forms';
import { Role } from '../Classes';
import version from  "../version";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  feVersion: any = version;

  openloginform: boolean = false;

  userident = new UntypedFormControl('', [Validators.required, Validators.minLength(6), this.validateUsername.bind(this)]);
  password = new UntypedFormControl('', [Validators.required, Validators.minLength(6), this.validatePassword.bind(this)]);


  loginform = this.fb.group({
    userident: this.userident,
    password: this.password
  });

  forminvalid: boolean = false;
  errormessage: string = "";
  sending: boolean = false;

  Role() {return Role;}

  loggedin() {
    return this.api.isLoggedIn();
  }

  hasRole(role: Role) {
    return this.api.user.hasRole(role);
  }

  username() {
    return this.api.getUsername();
  }

  userHasProblems() {
    if (this.api.isLoggedIn()) {
      if (!this.api.user.activated) return true;
    }

    return false;
  }

  userProblemText() {
    if (this.api.isLoggedIn()) {
      if (!this.api.user.activated) return "E-Mail not verified";
    }

    return "";
  }

  constructor(private api: ApiService, private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
  }

  keyDownOnLogin(event) {
    if(event.keyCode == 13) {
      this.login();
    }
  }

  validateUsername(control: AbstractControl): {[key: string]: any} | null  {
    var match = control.value.match(/[A-Za-z0-9_\-.@]+/i);
    if (match == null || match[0] != control.value) {
      return {'invalidUsername': true};
    }
    return null;
  }

  validatePassword(control: AbstractControl): {[key: string]: any} | null  {
    var match = control.value.match(/[A-Za-z0-9_\-!§$%&?^+*#\':;,.]+/i);
    if (match == null || match[0] != control.value) {
      return {'invalidPassword': true};
    }
    return null;
  }

  getErrorMessage(element) {
    if (element == "userident") {
      if (this.userident.hasError('required')) {
        return 'You must enter a value';
      } else if (this.userident.hasError('minlength')) {
        return 'Username too short';
      } else if (this.userident.hasError('invalidUsername')) {
        return 'Invalid Characters';
      }
    } else if (element == "password") {
      if (this.password.hasError('required')) {
        return 'You must enter a value';
      } else if (this.password.hasError('minlength')) {
        return 'Password too short';
      } else if (this.password.hasError('invalidPassword')) {
        return 'Invalid Characters';
      }
    }
  }

  login() {
    this.forminvalid = false;
    this.errormessage = "";

    this.sending = true;
    this.api.getToken(this.loginform.value.userident, this.loginform.value.password).then(data => {
      this.api.getUserInfo().then((data) => {
        this.api.setUser(data);
        this.sending = false;
      }).catch((err) => {
        this.sending = false;
        console.log(err);
      });
    }, err => {
      this.sending = false;
      console.log(err);
      this.forminvalid = true;
      this.errormessage = err.error.message;
    });
  }

  logout() {
    this.api.logout();
    location.reload();
  }
}
