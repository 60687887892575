<h1 mat-dialog-title>Login expired</h1>
<div mat-dialog-content>
  <p>Your Login has expired. Please login again to continue</p>
  <mat-form-field id="username" appearance="standard">
    <mat-label>Username</mat-label>
    <input matInput placeholder="Username" [(ngModel)]="logindata.username">
    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
  </mat-form-field>

  <mat-form-field id="password" appearance="standard">
    <mat-label>Password</mat-label>
    <input matInput type="password" placeholder="Password" [(ngModel)]="logindata.password">
    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="logindata" cdkFocusInitial>Login</button>
</div>