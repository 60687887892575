import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service';
import { CREvent } from '../Classes';
import { showErrorSnackbar } from '../utils';

interface EventType {

}

@Component({
  selector: 'app-admin-eventlist',
  templateUrl: './admin-eventlist.component.html',
  styleUrls: ['./admin-eventlist.component.scss']
})
export class AdminEventlistComponent implements OnInit {

  eventTypes: string[] = ["deckused", "moderation", "change", "user_changepw", "user_changemail", "user_delete", "user_canceldelete", "admin_active_"
,"admin_ban_", "admin_delete_", "admin_canceldelete_", "admin_email_", "admin_role_", "admin_password_", "deck_delete", "admin_featured_",
"admin_locked_", "admin_nsfw_", "admin_language_"]


  events: CREvent[] = [];
  currentPage = 0;
  pageSize = 25;
  eventCount: number = 0;
  loading: boolean = false;

  filtertext: string = "";
  sort: string = "none";
  isfilter: boolean = false;
  filterTypes = ["change"];

  constructor(private api: ApiService, private _snackBar: MatSnackBar) { }

  isLoggedIn() {
    return this.api.isLoggedIn();
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.onApiReady();
    });
  }

  onApiReady() {
    this.loadNew();
  }

  eventDescription(event: CREvent) {
    if (event.type == "deckused") {
      return "Deck \"" + event.deckcode + "\" was used in a game.";
    } else if (event.type == "moderation") {
      return event.username + " moderated the deck \"" + event.deckcode + "\".";
    } else if (event.type == "change") {
      if (event.origin.startsWith("user_changepw")) {
        return event.username + " changed their password.";
      } else if (event.origin.startsWith("user_changemail")) {
        return event.username + " changed their email.";
      } else if (event.origin.startsWith("user_delete")) {
        return event.username + " queued the deletion of their account.";
      } else if (event.origin.startsWith("user_canceldelete")) {
        return event.username + " cancelled the deletion of their account.";
      } else if (event.origin.startsWith("admin_active_")) {
        return event.username + "'s Activation State has been changed by " + event.origin.split("_")[2] + ".";
      } else if (event.origin.startsWith("admin_ban_")) {
        return event.username + "'s Banned State has been changed by " + event.origin.split("_")[2] + ".";
      } else if (event.origin.startsWith("admin_delete_")) {
        return event.username + " has been queued for deletion by " + event.origin.split("_")[2] + ".";
      } else if (event.origin.startsWith("admin_canceldelete_")) {
        return event.username + "'s deletion has been cancelled by " + event.origin.split("_")[2] + ".";
      } else if (event.origin.startsWith("admin_email_")) {
        return event.username + "'s email has been changed by " + event.origin.split("_")[2] + ".";
      } else if (event.origin.startsWith("admin_role_")) {
        return event.username + "'s role has been changed by " + event.origin.split("_")[2] + ".";
      } else if (event.origin.startsWith("admin_password_")) {
        return event.username + "'s password has been changed by " + event.origin.split("_")[2] + ".";
      } else if (event.origin.startsWith("deck_delete")) {
        return event.username + " deleted the deck \"" + event.deckcode + "\".";
      } else if (event.origin.startsWith("admin_featured_")) {
        return event.origin.split("_")[2] + " changed the Featured state the deck \"" + event.deckcode + "\".";
      } else if (event.origin.startsWith("admin_locked_")) {
        return event.origin.split("_")[2] + " changed the Locked state the deck \"" + event.deckcode + "\".";
      } else if (event.origin.startsWith("admin_nsfw_")) {
        return event.origin.split("_")[2] + " changed the NSFW state the deck \"" + event.deckcode + "\".";
      } else if (event.origin.startsWith("admin_language_")) {
        return event.origin.split("_")[2] + " changed the Language state the deck \"" + event.deckcode + "\".";
      }
    }

    return "Unknown event type: " + event.type;
  }

  loadNew() {
    if (this.loading) return;

    this.loading = true;
    this.events = [];

    if (this.isfilter) {
      this.api.getAdminEventsFilter(this.currentPage, this.pageSize, this.filtertext, this.filterTypes).then((data) => {
        this.events = data.events;
        this.eventCount = data.count;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        showErrorSnackbar(err, this._snackBar);
      });
    } else {
      this.api.getAdminEventsFilter(this.currentPage, this.pageSize, null, this.filterTypes).then((data) => {
        this.events = data.events;
        this.eventCount = data.count;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        showErrorSnackbar(err, this._snackBar);
      });
    }
  }

  typeFilterChanged() {
    this.isfilter = true;
    this.reload();
  }

  switchPage(event) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  reload() {
    this.loadNew();
  }

  applyFilters() {
    this.isfilter = true;
    this.currentPage = 0;
    this.reload();
  }

  clearFilters() {
    this.isfilter = false;
    this.filtertext = "";
    this.filterTypes = ["change"];
    this.currentPage = 0;
    this.reload();
  }
}
