<div id="int-container">
	<div id="int-sidebar" [style.left]="menuOpen? '0' : ''">
		<div id="closeWrapper">
			<button mat-icon-button color="white" aria-label="Menu" (click)="closeMenu()">
				<mat-icon>close</mat-icon>
			</button>
		</div>
		<div class="int-sidebar_option" routerLink="mydecks" routerLinkActive="selected" (click)="closeMenu()">My Decks</div>
		<div *ngIf="isModerator()" class="int-sidebar_option" routerLink="mod" routerLinkActive="selected" (click)="closeMenu()">Moderation</div>
		<div class="int-sidebar_option" routerLink="profile" routerLinkActive="selected" (click)="closeMenu()">Profile</div>
	</div>
	<div id="contentClickHandler" *ngIf="shadowVisible" [style.opacity]="menuOpen? '1' : '0'" (click)="closeMenu()">

	</div>
	<div class="int-content">
		<router-outlet></router-outlet>
	</div>
</div>