import { Component, OnInit } from '@angular/core';
import { CardDeck } from '../Classes';
import { ApiService } from '../api.service';
import { LogindialogComponent } from '../logindialog/logindialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { showErrorSnackbar } from '../utils';

@Component({
  selector: 'app-modlist',
  templateUrl: './modlist.component.html',
  styleUrls: ['./modlist.component.scss']
})
export class ModlistComponent implements OnInit {

  decks: CardDeck[] = [];

  modCardDeck: CardDeck = null;

  loading: boolean = true;

  decksLength() {
    if (this.decks == undefined) return 0;
    else return this.decks.length;
  }

  constructor(private api: ApiService, public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.onApiReady();
    });
  }

  onApiReady() {
    this.getDecks();
  }

  getDecks() {
    this.loading = true;

    this.api.getModDecks().then((data) => {
      this.decks = data;
      this.loading = false;
    }).catch((err) => {
      console.log(err);
      if (err.status == 401) {
        this.relogin().then((data) => {
          this.getDecks();
        }).catch((err) => {
          this.loading = false;
        });
      } else {
        this.loading = false;
        showErrorSnackbar(err, this._snackBar);
      }
    });
  }


  relogin() {
    return new Promise<void>((resolve, reject) => {
      const dialogRef = this.dialog.open(LogindialogComponent, {
        width: '700px',
        data: { username: this.api.user.username }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          reject();
          return;
        }

        this.api.getToken(result.username, result.password).then((data) => {
          resolve();
        }).catch((err) => {
          this._snackBar.open("There has been an Error logging you back in", null, {duration: 2000});
          reject();
        });
      });
    });
  }


}
