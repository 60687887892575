import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'empty-state',
  templateUrl: './emptystate.component.html',
  styleUrls: ['./emptystate.component.scss']
})
export class EmptystateComponent implements OnInit {

  @Input() topMsg: string = "";
  @Input() botMsg: string = "";

  @Input() btnText: string = "";
  @Input() btnTooltip: string = "";
  @Input() btnDisabled: boolean = false;

  @Output() btnClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  click() {
    this.btnClicked.emit();
  }

}
