<div class="deck-card">
    <div id="click-handler" (click)="click($event)">

    </div>
    <div class="deck-card-info">
        <div class="deck-card-title" [innerHTML]="cardDeck.name | sanitize | highlightSearch: searchTerm">

        </div>
        <div class="deck-card-desc" [innerHTML]="cardDeck.descHTML() | sanitize | highlightSearch: searchTerm">

        </div>
        <div *ngIf="hasCheckpoint && !cardDeck.locked" class="deck-card-checkpoint">
            Unsaved Changes
        </div>
        <div *ngIf="cardDeck.deckcode == ''" class="deck-card-checkpoint">
            {{cardDeck.createdate | date}}
        </div>
        <div *ngIf="cardDeck.locked" class="deck-card-checkpoint">
            Locked
        </div>
        <button *ngIf="showFavButton && isLoggedIn() && !isLoadingFav" class="fav-button" mat-icon-button color="primary" aria-label="Favorite" (click)="clickFav(); $event.stopPropagation();">
            <mat-icon>{{isFavorite? "favorite" : "favorite_border"}}</mat-icon>
        </button>
        <mat-spinner *ngIf="showFavButton && isLoggedIn() && isLoadingFav" class="fav-button" diameter="30" color="primary"></mat-spinner>
    </div>

    <div class="deck-card-wbcount">
        <div class="deck-card-bc-icon"><div class="deck-card-text">{{getBlacksCount()}}</div></div>
        <div class="deck-card-wc-icon"><div class="deck-card-text">{{getWhitesCount()}}</div></div>
        <div style="display: flex;"><mat-icon class="deck-card-language-icon">language</mat-icon><div class="deck-card-text-language">{{cardDeck.language.langCode}}</div></div>
        <mat-icon *ngIf="cardDeck.nsfw > 0" class="deck-card-nsfw-icon" svgIcon="nsfw"></mat-icon>
    </div>

    <div *ngIf="cardDeck.moderationLock && showModLock" class="deck-card-lockicon">
        <mat-icon>lock</mat-icon>
    </div>

    <div class="deck-card-deckcode" [innerHTML]="cardDeck.deckcode | sanitize | highlightSearch: searchTerm">

    </div>
    <div class="deck-card-menu">
        <div *ngIf="showDeckState" id="deck-card-state" [style.background]="getStateColor()">
            {{getStateText()}}
        </div>
        <button *ngIf="showCopyButtons" class="copy-button" mat-button aria-label="Copy URL" (click)="$event.stopPropagation();" [cdkCopyToClipboard]="'https://api.crcast.cc/' + cardDeck.deckcode">
            COPY URL
        </button>
        <button *ngIf="showCopyButtons" class="copy-button" mat-button aria-label="Copy Code" (click)="$event.stopPropagation();" [cdkCopyToClipboard]="cardDeck.deckcode">
            COPY CODE
        </button>
    </div>
</div>