import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-apidoc',
  templateUrl: './apidoc.component.html',
  styleUrls: ['./apidoc.component.scss']
})
export class ApidocComponent implements OnInit {

  deckinfo: string = "";
  deckcards: string = "";
  deckall: string = "";

  API_URL: string = environment.splitports? environment.deckApiUrl : environment.apiUrl;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.getCode(this.API_URL + "cc/decks/VD5MM/").then((data) => {
      this.deckinfo = data;
    }).catch((reason) => {
      this.deckinfo = reason;
    });

    this.getCode(this.API_URL + "cc/decks/VD5MM/cards").then((data) => {
      this.deckcards = data;
    }).catch((reason) => {
      this.deckinfo = reason;
    });

    this.getCode(this.API_URL + "cc/decks/VD5MM/all").then((data) => {
      this.deckall = data;
    }).catch((reason) => {
      this.deckinfo = reason;
    });
  }

  getCode(url: string) {
    return new Promise<string>((resolve, reject) => {
      this.api.rawGetRequest(url, {noTrackUsage: true}).then((data) => {
        resolve(">> GET " + url + "\n\n" + JSON.stringify(data, null, "    "));
      }).catch((reason) => {
        reject(">> GET " + url + "\n\nError retrieving response")
      });
    });
  }
}
