<div id="modlist-wrapper">
    <div id="modlist-toolbar">
        <account-menu-button></account-menu-button>

        <button id="refreshbtn" mat-icon-button aria-label="Reload Modlist" (click)="getDecks()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>

    <empty-state *ngIf="decks != null && decks.length == 0 && !loading" topMsg="There are no pending Decks" btnText="Refresh" (btnClicked)="getDecks()"></empty-state>

    <div *ngIf="decks != null && (decks.length != 0 || loading)" id="modlist-list">
        <div *ngIf="loading" class="spinner-overlay">
            <mat-progress-spinner
              class="spinner"
              color="#2196f3"
              mode="indeterminate">
          </mat-progress-spinner>
        </div>

        <comp-carddeck-card *ngFor="let deck of decks" [cardDeck]="deck" class="deck-card" [showDeckState]="true" [showModLock]="true" [routerLink]="['./', deck.deckcode]"></comp-carddeck-card>
    </div>
</div>