import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service';
import { CardDeck } from '../Classes';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private api: ApiService, private _snackBar: MatSnackBar) { }

  showCardDeck: boolean = false;
  selectedDeck: CardDeck = null;

  apiVersion: any = {};

  ngOnInit(): void {
    this.api.getVersion().then((data) => {
      this.apiVersion = data;
    }).catch((reason) => {
      this._snackBar.open("Error retrieving API Version", null, {duration: 2000});
    });
  }
}
