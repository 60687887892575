<h1 mat-dialog-title>Change Account E-Mail</h1>
<div mat-dialog-content>
    <p *ngIf="!data.isAdmin">
        If you want to change the E-Mail Address of this Account please fill out the Form below.
        <br/>
        You need to activate the new E-Mail Address later
        <br/>
        E-Mail Address currently used for this Account:
        <code class="noselect" style="width: fit-content; margin-top: 8px; background-color: #1b1b1b; border-radius: 4px; color: white; padding: 6px; margin-bottom: 16px; display: block;">
            {{data.oldmail}}
        </code>
    </p>
    <p *ngIf="data.isAdmin">
        If you want to change the E-Mail Address of the Account "{{data.user.username}}" please fill out the Form below.
        <br/>
        You can select the Activation Status of the Account later
        <br/>
        E-Mail Address currently used for the Account:
        <code class="noselect" style="width: fit-content; margin-top: 8px; background-color: #1b1b1b; border-radius: 4px; color: white; padding: 6px; margin-bottom: 16px; display: block;">
            {{data.oldmail}}
        </code>
    </p>
    <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Enter your new E-Mail Address</mat-label>
        <input matInput [(ngModel)]="newmail">
    </mat-form-field>
    <mat-form-field *ngIf="!data.isAdmin" appearance="outline" style="width: 100%;">
        <mat-label>Enter your current Password</mat-label>
        <input matInput type="password" [(ngModel)]="password">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-flat-button [disabled]="!data.isAdmin && (newmail == '' || password == '')" [mat-dialog-close]="{email: newmail, password: password}" cdkFocusInitial>Change</button>
</div>