<h1 mat-dialog-title>Import Card Deck from JSON</h1>
<div mat-dialog-content>
  <p>The JSON Import only works with some Card Decks Formats. If your Format isn't supported, you can contact us on our Discord.</p>
  <mat-form-field id="jsoninput" appearance="fill">
    <mat-label>JSON</mat-label>
    <textarea matInput [(ngModel)]="jsoninput"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="jsoninput" cdkFocusInitial>Import</button>
</div>