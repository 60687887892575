<div id="admin-container">
	<div id="admin-sidebar">
        <div id="admin-header" class=" noselect">
            Administration
        </div>
        <div id="admin-list">
            <div class="admin-sidebar_option noselect" routerLink="decks" routerLinkActive="selected">Decks</div>
            <div class="admin-sidebar_option noselect" routerLink="users" routerLinkActive="selected">Users</div>
            <div class="admin-sidebar_option noselect" routerLink="events" routerLinkActive="selected">Events</div>
            <div class="admin-sidebar_option noselect" routerLink="tasks" routerLinkActive="selected">Tasks</div>
        </div>
	</div>
	<div class="admin-content">
		<router-outlet></router-outlet>
	</div>
</div>