<h1 mat-dialog-title>Delete Deck</h1>
<div mat-dialog-content>
    <p>
        Do you really want to delete the Deck: "{{data.deck}}"?
        <br/>
        This cannot be undone!
    </p>
</div>
<div mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-flat-button [mat-dialog-close]="true" color="warn" cdkFocusInitial>Delete</button>
</div>