import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { CardDeck, languages } from '../Classes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { showErrorSnackbar } from '../utils';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-decklist',
  templateUrl: './decklist.component.html',
  styleUrls: ['./decklist.component.scss']
})
export class DecklistComponent implements OnInit {

  showFilters: boolean = false;

  private anchor: ElementRef;
  @ViewChild('anchor') set content(content: ElementRef) {
     if(content) {
         this.anchor = content;

         const options = {
          root: null
        };

       this.observer = new IntersectionObserver(([entry]) => {
         console.log("Anchor overlap");
          entry.isIntersecting && this.loadNew();
        }, options);

        this.observer.observe(this.anchor.nativeElement);
     }
  }

  private observer: IntersectionObserver;

  lastPosition: number;
  lastRoute: string;
  @ViewChild('list') list: ElementRef;

  decks: CardDeck[] = null;
  currentPage = -1;
  pageSize = 50;
  deckCount: number = 0;
  loading: boolean = false;
  loadingFav: string[] = [];

  filtertext: string = "";
  filterLanguage: string[] = [];
  filterSFW: boolean = false;
  filterFavorites: boolean = false;
  sort: string = "none";
  isfilter: boolean = false;

  get element() {
    return this.host.nativeElement;
  }

  isFavorite(deckcode) {
    return this.api.isLoggedIn() && this.api.user.favorites.indexOf(deckcode) != -1;
  }

  isLoadingFav(deckcode) {
    return this.api.isLoggedIn() && this.loadingFav.indexOf(deckcode) != -1;
  }

  langs() {
    return languages;
  }

  constructor(private host: ElementRef, private api: ApiService, private _snackBar: MatSnackBar, private router: Router) { }

  isLoggedIn() {
    return this.api.isLoggedIn();
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.onApiReady();
    });

    // Fix Scrolling on Page reuse
    this.router.events.pipe(
      filter((events) => events instanceof NavigationStart || events instanceof NavigationEnd)
    ).subscribe(event => {
      if (event instanceof NavigationStart && event.url !== this.lastRoute) {
        this.lastRoute = this.router.url
        this.lastPosition = this.list.nativeElement.scrollTop
      }
      else if (event instanceof NavigationEnd && event.url === this.lastRoute) {
        this.list.nativeElement.scrollTop  = this.lastPosition
      }
    })
  }

  onApiReady() {
    if (this.api.isLoggedIn()) {
      this.api.getFavoriteDecks().then((data) => {

      }).catch((err) => {
        showErrorSnackbar(err, this._snackBar);
      });
    }
  }

  ngAfterViewInit() {
    console.log("afterinit");
    let w = document.getElementById("decklist-content").clientWidth;
    let h = document.getElementById("decklist-content").clientHeight;

    w -= 32;
    h -= 64 + 16;

    w = Math.floor(w / 238);
    h = Math.floor(h / 238);

    console.log("Amount in Row: " + w);
    console.log("Amount in Column: " + h);
    console.log("Amount Cards: " + w * h);
    console.log("Loading Cards: " + w * h * 2);
    this.pageSize = Math.max(w * h * 2, 10);
  }

  loadNew(isNew: boolean = false) {
    if (this.loading) return;

    this.loading = true;

    this.currentPage++;

    if (isNew || this.decks == null) {
      this.decks = [];
      this.currentPage = 0;
    }

    if (this.isfilter) {
      this.api.getCardDecksSortFilter(this.currentPage, this.pageSize, this.sort, this.filtertext, this.filterSFW, this.filterFavorites, this.filterLanguage).then((data) => {
        this.decks = this.decks.concat(data.decks);
        this.deckCount = data.count;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        showErrorSnackbar(err, this._snackBar);
      });
    } else {
      this.api.getCardDecksSortFilter(this.currentPage, this.pageSize, this.sort, null, null, null, null).then((data) => {
        this.decks = this.decks.concat(data.decks);
        this.deckCount = data.count;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        showErrorSnackbar(err, this._snackBar);
      });
    }
  }

  reload() {
    this.loadNew(true);
  }

  resort(event) {
    this.sort = event.value;
    this.reload();
  }

  applyFilters() {
    this.isfilter = true;
    this.reload();
  }

  clearFilters() {
    this.isfilter = false;
    this.filtertext = "";
    this.filterLanguage = [];
    this.filterSFW = false;
    this.filterFavorites = false;
    this.reload();
  }

  favoriteClicked(deck) {
    this.loadingFav.push(deck.deckcode);

    if (this.isFavorite(deck.deckcode)) {
      this.api.removeFavorite(deck.deckcode).then((data) => {
        this._snackBar.open("Successfully removed " + deck.deckcode + " from your Favorites", null, {duration: 2000});
        this.loadingFav.splice(this.loadingFav.indexOf(deck.deckcode), 1);
      }).catch((err) => {
        this._snackBar.open("There has been an Error removing " + deck.deckcode + " from your Favorites", null, {duration: 2000});
        this.loadingFav.splice(this.loadingFav.indexOf(deck.deckcode), 1);
      });
    } else {
      this.api.addFavorite(deck.deckcode).then((data) => {
        this._snackBar.open("Successfully added " + deck.deckcode + " to your Favorites", null, {duration: 2000});
        this.loadingFav.splice(this.loadingFav.indexOf(deck.deckcode), 1);
      }).catch((err) => {
        this._snackBar.open("There has been an Error adding " + deck.deckcode + " to your Favorites", null, {duration: 2000});
        this.loadingFav.splice(this.loadingFav.indexOf(deck.deckcode), 1);
      });
    }
  }
}
