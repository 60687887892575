
<div id="pageGrid">
    <div id="topBar">
        <div id="deckName" class="notmobile">
            {{cardDeck.name}}
        </div>

        <div id="deckCode">
            {{cardDeck.deckcode}}
        </div>

        <div id="private">
            <mat-icon *ngIf="cardDeck.private">lock</mat-icon>
            <mat-icon *ngIf="!cardDeck.private">public</mat-icon>
        </div>

        <mat-icon *ngIf="cardDeck.nsfw > 0" id="nsfw-icon" svgIcon="nsfw"></mat-icon>

        <div id="buttonWrapper">
            <button mat-stroked-button id="saveBtn" (click)="btnSaveClick()">Save</button>
            <button mat-stroked-button id="cancelBtn" (click)="btnCancelClick()">Cancel</button>
        </div>
    </div>

    <div id="info-wrapper">
        <h1 class="mobile">{{cardDeck.name}}</h1>
        <p>{{cardDeck.description}}</p>
    </div>

    <div id="moderation-wrapper">
        <div id="feedback-wrapper">
            <mat-form-field  id="feedback-wrapper" appearance="fill">
                <mat-label>Mod feedback</mat-label>
                <textarea matInput [(ngModel)]="cardDeck.moderationNotes"></textarea>
            </mat-form-field>
        </div>

        <div id="modactions">
            <mat-button-toggle-group id="deck-accept" #group="matButtonToggleGroup" [(ngModel)]="acceptCardDeck2" (change)="deckAcceptDecline($event)">
                <mat-button-toggle value="false" aria-label="Decline">
                    <mat-icon style="color: #d50000;">close</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="true" aria-label="Accept">
                <mat-icon style="color: #00c853;">done</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>

            <button mat-raised-button (click)="acceptPending()">Accept all Pending Cards</button>

            <mat-slide-toggle id="showOnlyPending" [(ngModel)]="onlyPending">Show only pending</mat-slide-toggle>

            <mat-form-field appearance="fill" id="nsfwState">
                <mat-label>NSFW</mat-label>
                <mat-select [(ngModel)]="nsfwState">
                <mat-option [value]="0">Not NSFW</mat-option>
                <mat-option [value]="1">NSFW</mat-option>
                <mat-option [value]="2">Force NSFW</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <mat-tab-group id="cardTabs" class="mobile" dynamicHeight animationDuration="0ms" mat-stretch-tabs="true">
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="cards-header">
                    <div class="title">
                        Black Cards
                    </div>
                    <div class="counter">
                        {{onlyPendingBlacks().length}} ({{cardDeck.blacks.length}})
                    </div>
                </div>
            </ng-template>
            <div id="blackCards" class="cardWrapper">
                <div class="cardgrid">
                    <blackcard *ngFor="let bc of onlyPending? onlyPendingBlacks() : cardDeck.blacks" [accepted]="stateBlacks.get(bc.id)" [blackCard]="bc" [showEditButtons]="false" [showModButtons]="true" (modClicked)="cardAcceptDeclineBlack($event)"></blackcard>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="cards-header">
                    <div class="title">
                        White Cards
                    </div>
                    <div class="counter">
                        {{onlyPendingWhites().length}} ({{cardDeck.whites.length}})
                    </div>
                </div>
            </ng-template>
            <div id="whiteCards" class="cardWrapper">
                <div class="cardgrid">
                    <whitecard *ngFor="let wc of onlyPending? onlyPendingWhites() : cardDeck.whites" [accepted]="stateWhites.get(wc.id)" [whiteCard]="wc" [showEditButtons]="false" [showModButtons]="true" (modClicked)="cardAcceptDeclineWhite($event)"></whitecard>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

    <div id="blackCards" class="cardWrapper notmobile">
        <div class="cards-header">
            <div class="title">
                Black Cards
            </div>
            <div class="counter">
                {{onlyPendingBlacks().length}} ({{cardDeck.blacks.length}})
            </div>
        </div>
        <div class="cardgrid">
            <blackcard *ngFor="let bc of onlyPending? onlyPendingBlacks() : cardDeck.blacks" [accepted]="stateBlacks.get(bc.id)" [blackCard]="bc" [showEditButtons]="false" [showModButtons]="true" (modClicked)="cardAcceptDeclineBlack($event)"></blackcard>
        </div>
    </div>

    <div id="delimiter" class="notmobile">

    </div>

    <div id="whiteCards" class="cardWrapper notmobile">
        <div class="cards-header">
            <div class="title">
                White Cards
            </div>
            <div class="counter">
                {{onlyPendingWhites().length}} ({{cardDeck.whites.length}})
            </div>
        </div>
        <div class="cardgrid">
            <whitecard *ngFor="let wc of onlyPending? onlyPendingWhites() : cardDeck.whites" [accepted]="stateWhites.get(wc.id)" [whiteCard]="wc" [showEditButtons]="false" [showModButtons]="true" (modClicked)="cardAcceptDeclineWhite($event)"></whitecard>
        </div>
    </div>
</div>

<div *ngIf="saving" id="spinner-overlay">
    <mat-progress-spinner
      id="spinner"
      color="#2196f3"
      mode="indeterminate">
  </mat-progress-spinner>
</div>