<div id="rrpw-wrapper">
    <div id="rrpw-title">
        Request Password Reset Link
    </div>

    <div *ngIf="requesting" id="spinner-overlay">
        <mat-progress-spinner
        id="spinner"
        color="#2196f3"
        mode="indeterminate">
        </mat-progress-spinner>
    </div>

    <div *ngIf="!completed" id="rrpw-notcomplete">
        <div id="rrpw-complete-text">
            Enter your E-Mail to Request a Reset Link <br/>
        </div>

        <mat-form-field appearance="outline" id="rrpw-email">
            <mat-label>E-Mail</mat-label>
            <input matInput placeholder="E-Mail" [(ngModel)]="email" [disabled]="requesting || completed">
            <mat-icon matSuffix>email</mat-icon>
        </mat-form-field>

        <button id="rrpw-request-button" mat-flat-button (click)="sendRequest()" [disabled]="email == ''">Request Reset Link</button>
    </div>

    <div *ngIf="completed" id="rrpw-complete">
        <div id="rrpw-complete-text">
            A Password Reset Link has been sent to the E-Mail:
        </div>
        <div id="rrpw-complete-email">{{email}}</div>

        <div id="rrpw-complete-text2">
            Check your E-Mails for the Link.<br/>
            If there is any Problem contact us via <a href="https://twitter.com/sirjuicebag" target="_blank" rel="noopener noreferrer" class="rrpw-contactlink">Twitter</a>, <a href="https://discord.gg/85GBhxd" target="_blank" rel="noopener noreferrer" class="rrpw-contactlink">Discord</a> or <a href="https://www.reddit.com/r/CLRTD/" target="_blank" rel="noopener noreferrer" class="rrpw-contactlink">Reddit</a>
        </div>

        <button id="rrpw-complete-button" mat-flat-button (click)="backToHome()">Back to Home</button>
    </div>
</div>
