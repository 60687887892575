<h1 mat-dialog-title>Load Autosave</h1>
<div mat-dialog-content>
    <p>
        There are unsaved changes to the Deck "{{data.deck}}" from {{data.createdate | date}}
        <br/>
        Do you want to load them?
        <br/>
        Otherwise the Autosave will be deleted!
    </p>

    New Cards: {{data.new}} <br/>
    Edited Cards: {{data.edited}} <br/>
    Deleted Cards: {{data.deleted}} <br/>
</div>
<div mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="false">No</button>
    <button mat-flat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>Yes</button>
</div>