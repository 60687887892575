import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { CardDeck, languages } from '../Classes';
import { ErrorDialog } from '../deckview/deckview.component';
import { Location } from '@angular/common'
import { LogindialogComponent } from '../logindialog/logindialog.component';
import { showErrorSnackbar } from '../utils';


@Component({
  selector: 'app-deckcreator',
  templateUrl: './deckcreator.component.html',
  styleUrls: ['./deckcreator.component.scss']
})
export class DeckcreatorComponent implements OnInit {
  originalDeck: CardDeck = new CardDeck();
  cardDeck: CardDeck = new CardDeck();

  checkpoint: number;
  loading: boolean = true;
  showHelp: boolean = false;

  changeBlack = {};
  changeWhite = {};

  get limits() { return this.api.limits; }

  canNotSave() {
    if (this.cardDeck.name.trim() == "") return true;
    if (!this.limits.checkBlackLimit(this.cardDeck.blacks.length)) return true;
    if (!this.limits.checkWhiteLimit(this.cardDeck.whites.length)) return true;
    return false;
  }

  languages() {
    return languages;
  }

  blackCards() {
    let newBlacks = [];
    this.cardDeck.blacks.forEach(bl => {
      if (this.changeBlack[bl.id] != -1) {
        newBlacks.push(bl);
      }
    });
    return newBlacks;
  }

  whiteCards() {
    let newWhites = [];
    this.cardDeck.whites.forEach(wh => {
      if (this.changeWhite[wh.id] != -1) {
        newWhites.push(wh);
      }
    });
    return newWhites;
  }

  isChangedBlack(id: number): boolean {
    if (id == -1) return true;
    if (Object.keys(this.changeBlack).includes("" + id)) return true;
  }

  isChangedWhite(id: number): boolean {
    if (id == -1) return true;
    if (Object.keys(this.changeWhite).includes("" + id)) return true;
  }

  get bIsNSFW() {
    return this.cardDeck.nsfw > 0
  }

  set bIsNSFW(newValue: boolean) {
    this.cardDeck.nsfw = newValue ? 1 : 0
  }

  constructor(private api: ApiService, private location: Location, private route: ActivatedRoute, public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.onApiReady();
    });
  }

  onApiReady() {
    if (!(this.api.isLoggedIn()? this.api.user.activated : false)) {
      this.btnCloseClick();
    }

    let deckcode = this.route.snapshot.paramMap.get('deckcode');

    // Check for Checkpoint

    this.checkpoint = parseInt(deckcode);

    if (isNaN(this.checkpoint) || deckcode.length == 5) {
      this.checkpoint = -1;
    }

    if (this.checkpoint != -1) {
      // Is loading a Checkpoint
      console.log("Loading Checkpoint: " + this.checkpoint);
      let check = this.getCheckpoint();
      if (check != null) {
        this.loadCheckpoint(check);
        this.loading = false;
      }
    } else if (deckcode == "new") {
      // Is creating a new Deck
      console.log("New Deck");
      this.cardDeck = new CardDeck({"name": "Untitled Deck"});
      this.loading = false;
    } else {
      // Is loading a normal Deck
      console.log("Loading Deck: " + deckcode);
      this.api.getUserDeck(deckcode).then((data) => {
        console.log(data);
        this.originalDeck = data;
        this.cardDeck = new CardDeck(JSON.parse(JSON.stringify(this.originalDeck.serialize())));

        if (this.hasCheckpoint() && !this.cardDeck.locked) {
          this.openCheckpointDialog();
        }
        this.loading = false;
      }).catch((err) => {
        const dialogRef = this.dialog.open(ErrorDialog, {
          width: '250px',
          data: err
        });

        dialogRef.afterClosed().subscribe(result => {
          this.btnCloseClick();
        });
      });
    }
  }

  btnCancelClick() {
    this.btnCloseClick();
  }

  btnSaveClick(publish) {
    this.loading = true;

    if (this.cardDeck.deckcode == "") {

      this.api.newCardDeck(this.cardDeck, publish).then((data) => {
        this.deleteCheckpoint(this.cardDeck);
        this.btnCloseClick();
      }).catch((err) => {
        console.log("error");
        console.log(err);
        if (err.status == 401) {
          this.relogin().then((data) => {
            this.btnSaveClick(publish);
          }).catch((err) => {
            this.loading = false;
          });
        } else {
          this.loading = false;
          showErrorSnackbar(err, this._snackBar);
        }
      });
    } else {
      this.api.updateCardDeck(this.cardDeck, this.changeBlack, this.changeWhite, publish).then((data) => {
        this.deleteCheckpoint(this.cardDeck);
        this.btnCloseClick();
      }).catch((err) => {
        console.log(err);
        if (err.status == 402) {
          this.relogin().then((data) => {
            this.btnSaveClick(publish);
          }).catch((err) => {
            this.loading = false;
          });
        } else {
          this.loading = false;
          showErrorSnackbar(err, this._snackBar);
        }
      });
    }
  }

  btnDeleteClick() {
    this.openDeleteDialog();
  }

  btnCloseClick() {
    this.location.back()
  }

  saveDeck() {
    this.saveCheckpoint(this.cardDeck);
  }

  addBlackCard(blackCard) {
    blackCard.pick = blackCard.getPick();
    blackCard.draw = blackCard.getPick();

    this.cardDeck.blacks.unshift(blackCard);
    this.saveCheckpoint(this.cardDeck);

  }

  saveBlackCard(data) {
    if (data.new.id != -1) {
      this.changeBlack[data.new.id] = 0;
    }
    this.saveCheckpoint(this.cardDeck);
  }

  deleteBlackCard(blackCard) {
    let i = this.cardDeck.blacks.indexOf(blackCard);
    if (i > -1) {
      if (blackCard.id == -1) this.cardDeck.blacks.splice(i, 1);
      else this.changeBlack[blackCard.id] = -1;
    }
    this.saveCheckpoint(this.cardDeck);
  }


  addWhiteCard(whiteCard) {
    this.cardDeck.whites.unshift(whiteCard);
    this.saveCheckpoint(this.cardDeck);
  }

  saveWhiteCard(data) {
    if (data.new.id != -1) {
      this.changeWhite[data.new.id] = 0;
    }
    this.saveCheckpoint(this.cardDeck);
  }

  deleteWhiteCard(whiteCard) {
    let i = this.cardDeck.whites.indexOf(whiteCard);
    if (i > -1) {
      if (whiteCard.id == -1) this.cardDeck.whites.splice(i, 1);
      else this.changeWhite[whiteCard.id] = -1;
    }
    this.saveCheckpoint(this.cardDeck);
  }

  saveCheckpoint(deck: CardDeck) {
    let saveData = {deck: deck.serialize(), changeBlack: this.changeBlack, changeWhite: this.changeWhite, createdate: new Date()}

    if (deck.deckcode == "") {
      //new Deck
      if (this.checkpoint == -1 || this.checkpoint == undefined) {
        // Needs a new Checkpoint
        let takenCheckpoints = [];

        let index = 0;
        while (true) {
          let key = localStorage.key(index);
          if (key == null) break;

          if (key.startsWith("checkpoint_")) {
            try {
              let checkpointIndex = parseInt(key.substring(11));
              if (!isNaN(checkpointIndex)) {
                takenCheckpoints.push(checkpointIndex);
              }
            } catch (e) {

            }
          }

          index++;
        }

        let nextCheckpointId = 0;
        while (true) {
          if (takenCheckpoints.indexOf(nextCheckpointId) == -1) {
            break;
          }

          nextCheckpointId++;
        }

        console.log("Next Checkpoint ID: " + nextCheckpointId);
        this.checkpoint = nextCheckpointId;
      }

      // Already has a Checkpoint or has gotten one
      localStorage.setItem("checkpoint_" + this.checkpoint, JSON.stringify(saveData))

    } else {
      localStorage.setItem("checkpoint_" + deck.deckcode, JSON.stringify(saveData))
    }
  }

  deleteCheckpoint(deck: CardDeck) {
    if (deck.deckcode == "") {
      if (this.checkpoint != -1 && this.checkpoint != undefined) {
        localStorage.removeItem("checkpoint_" + this.checkpoint)
      }
    } else {
      localStorage.removeItem("checkpoint_" + deck.deckcode)
    }
  }

  getCheckpoint() {
    let check = null;

    if (this.cardDeck.deckcode == "") {
      //new Deck
      if (this.checkpoint != -1 && this.checkpoint != undefined) {
        check = localStorage.getItem("checkpoint_" + this.checkpoint)
      }
    } else {
      check = localStorage.getItem("checkpoint_" + this.cardDeck.deckcode)
    }

    if (check != null) {
      let saveData = JSON.parse(check);

      return {deck: new CardDeck(saveData.deck), changeBlack: saveData.changeBlack, changeWhite: saveData.changeWhite, createdate: saveData.createdate};
    }

    return null;
  }

  loadCheckpoint(checkpoint: any) {
    this.cardDeck = checkpoint.deck;
    this.changeBlack = checkpoint.changeBlack;
    this.changeWhite = checkpoint.changeWhite;
  }

  hasCheckpoint(): boolean {
    return this.getCheckpoint() != null;
  }

  exportDeck(format: string) {
    console.log("Exporting Deck in " + format + " format");
    let tmp: any;
    switch(format) {
      case "crcast":
        tmp = {deckcode: "", language: "", name: "", description: "", private: false, nsfw: false, blacks: [], whites: []};

        tmp.deckcode = this.cardDeck.deckcode;
        tmp.language = this.cardDeck.language.langCode;
        tmp.name = this.cardDeck.name;
        tmp.description = this.cardDeck.description;
        tmp.private = this.cardDeck.private;
        tmp.nsfw = this.cardDeck.nsfw > 0;

        tmp.blacks = this.cardDeck.blacks.map(b => {
          return b.text.split(/\s*_\s*/g);
        });

        tmp.whites = this.cardDeck.whites.map(w => {
          return [w.text];
        });

        break;
      case "allbadcards":
        tmp = {packName: "", isPublic: false, blackCards: [], whiteCards: []};

        tmp.packName = this.cardDeck.name;
        tmp.isPublic = !this.cardDeck.private;

        tmp.blackCards = this.cardDeck.blacks.map(b => {
          return b.text;
        });

        tmp.whiteCards = this.cardDeck.whites.map(w => {
          return w.text;
        });

        break;
      case "pyxapp":
        tmp = {name: "", description: "", watermark: "", calls: [], responses: []};

        tmp.name = this.cardDeck.name;
        tmp.description = this.cardDeck.description;
        tmp.watermark = this.cardDeck.deckcode;

        tmp.calls = this.cardDeck.blacks.map(b => {
          let arr = b.text.split(/\s*_\s*/g);
          if (arr.length == 1) {
            arr = [arr[0], ""];
          }
          return {text: arr};
        });

        tmp.responses = this.cardDeck.whites.map(w => {
          return {text: [w.text]};
        });

        break;
      case "manydecks":
        tmp = {name: "", calls: [], responses: []};

        tmp.name = this.cardDeck.name;

        tmp.calls = this.cardDeck.blacks.map(b => {
          let firstArr: any[] = b.text.split(/\s*_\s*/g);
          if (firstArr.length == 1) {
            firstArr = [firstArr[0], {}];
          } else {
            let firstArr2: any[] = [];
            firstArr.forEach(b => {
              if (b != "") {
                firstArr2.push(b);
              }
              firstArr2.push({});
            });
            firstArr2.splice(firstArr2.length - 1, 1);
            firstArr = firstArr2;
          }

          return [firstArr];
        });

        tmp.responses = this.cardDeck.whites.map(w => {
          return w.text;
        });

        break;
    }

    console.log(JSON.stringify(tmp, null, '\t'));
    let json = JSON.stringify(tmp, null, '\t');

    let a = document.createElement('a');

    let blob = new Blob([json], {type: "octet/stream"}),
    url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = this.cardDeck.deckcode + "_" + format + ".json";
    a.click();
    window.URL.revokeObjectURL(url);
  }

  openCheckpointDialog() {
    let check = this.getCheckpoint();

    let amountNew = check.deck.blacks.filter(bc => bc.id == -1).length +
                    check.deck.whites.filter(wc => wc.id == -1).length;

    let amountDeleted = Object.values(check.changeBlack).filter(cb => cb == -1).length +
    Object.values(check.changeWhite).filter(cw => cw == -1).length;

    let amountEdited = Object.values(check.changeBlack).filter(cb => cb == 0).length +
    Object.values(check.changeWhite).filter(cw => cw == 0).length;

    const dialogRef = this.dialog.open(CheckpointLoadDialog, {
      data: {deck: this.cardDeck.name, new: amountNew, deleted: amountDeleted, edited: amountEdited, createdate: check.createdate}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      if (result) {
        this.loading = true;
        let checkpoint = this.getCheckpoint();
        this.loadCheckpoint(checkpoint);
        this.loading = false;
      } else {
        this.deleteCheckpoint(this.cardDeck);
      }
    });
  }

  openDeleteDialog() {
    const dialogRef = this.dialog.open(DeckDeleteDialog, {
      data: {deck: this.cardDeck.name}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      if (result) {
        this.loading = true;

        if (this.originalDeck.deckcode == "") {
          this.deleteCheckpoint(this.cardDeck);
          this.btnCloseClick();
        } else
        this.api.deleteCardDeck(this.originalDeck.deckcode).then((data) => {
          this.deleteCheckpoint(this.cardDeck);
          this.btnCloseClick();
        }).catch((err) => {
          console.log("error");
          console.log(err);

          if (err.status == 401) {
            this.relogin().then((data) => {
              this.openDeleteDialog();
            }).catch((err) => {
              this.loading = false;
            });
          } else {
            this.loading = false;
            showErrorSnackbar(err, this._snackBar);
          }
        });
      }
    });
  }

  relogin() {
    return new Promise<void>((resolve, reject) => {
      const dialogRef = this.dialog.open(LogindialogComponent, {
        width: '700px',
        data: { username: this.api.user.username }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          reject();
          return;
        }

        this.api.getToken(result.username, result.password).then((data) => {
          resolve();
        }).catch((err) => {
          this._snackBar.open("There has been an Error logging you back in", null, {duration: 2000});
          reject();
        });
      });
    });
  }
}

@Component({
  selector: 'delete-dialog',
  templateUrl: 'delete-dialog.html',
})
export class DeckDeleteDialog {

  constructor(
    public dialogRef: MatDialogRef<DeckDeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'checkpoint-load-dialog',
  templateUrl: 'checkpoint-load-dialog.html',
})
export class CheckpointLoadDialog {

  constructor(
    public dialogRef: MatDialogRef<CheckpointLoadDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}