<div id="pageGrid">
    <div id="topBar">

        <div id="deckName" class="notmobile">
            {{cardDeck.name}}
        </div>

        <div *ngIf="cardDeck.deckcode != ''" id="deckCode" matRipple matRippleColor="#4caf50a0" matTooltip="Copy the Deckcode to Clipboard" [cdkCopyToClipboard]="cardDeck.deckcode">
            {{cardDeck.deckcode}}
            <mat-icon>content_copy</mat-icon>
        </div>

        <mat-icon *ngIf="cardDeck.nsfw > 0" class="nsfw-icon" svgIcon="nsfw"></mat-icon>

        <div id="buttonWrapper">
            <button mat-icon-button class="actionButton" (click)="btnCloseClick()">
                <mat-icon>close</mat-icon>
            </button>

            <button mat-icon-button class="actionButton" matTooltip="Copies link for CAH sites with URL import" [cdkCopyToClipboard]="'https://api.crcast.cc/' + cardDeck.deckcode">
                <mat-icon>send_to_mobile</mat-icon>
            </button>

            <button mat-icon-button class="actionButton" matTooltip="Copies direct Link to this Page" [cdkCopyToClipboard]="'https://crcast.cc/deck/' + cardDeck.deckcode">
                <mat-icon>share</mat-icon>
            </button>

            <button *ngIf="isLoggedIn() && !loadingFav" class="actionButton" mat-icon-button aria-label="Favorite" (click)="clickFav()">
                <mat-icon>{{isFavorite()? "favorite" : "favorite_border"}}</mat-icon>
            </button>

            <mat-spinner *ngIf="loadingFav && !loading" class="actionButton" diameter="30" color="white" style="margin: 5px;"></mat-spinner>
        </div>
    </div>

    <div id="info-left" class="info">
        <h1 class="mobile">{{cardDeck.name}}</h1>
        <p>{{cardDeck.description}}</p>
    </div>

    <div id="info-right" class="info">
        <p>Language: {{cardDeck.language.displayName}}</p>
    </div>

    <mat-tab-group id="cardTabs" class="mobile" dynamicHeight animationDuration="0ms" mat-stretch-tabs="true">
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="cards-header">
                    <div class="title">
                        Black Cards
                    </div>
                    <div class="counter">
                        {{cardDeck.blacks.length}}
                    </div>
                </div>
            </ng-template>
            <div id="blackCards" class="cardWrapper">
                <div class="cardgrid">
                    <blackcard *ngFor="let bc of cardDeck.blacks" [blackCard]="bc" [showEditButtons]="false"></blackcard>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="cards-header">
                    <div class="title">
                        White Cards
                    </div>
                    <div class="counter">
                        {{cardDeck.whites.length}}
                    </div>
                </div>
            </ng-template>
            <div id="whiteCards" class="cardWrapper">
                <div class="cardgrid">
                    <whitecard *ngFor="let wc of cardDeck.whites" [whiteCard]="wc" [showEditButtons]="false"></whitecard>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

    <div id="blackCards" class="cardWrapper notmobile">
        <div class="cards-header">
            <div class="title">
                Black Cards
            </div>
            <div class="counter">
                {{cardDeck.blacks.length}}
            </div>
        </div>
        <div class="cardgrid">
            <blackcard *ngFor="let bc of cardDeck.blacks" [blackCard]="bc" [showEditButtons]="false"></blackcard>
        </div>
    </div>

    <div id="delimiter" class="notmobile">

    </div>

    <div id="whiteCards" class="cardWrapper notmobile">
        <div class="cards-header">
            <div class="title">
                White Cards
            </div>
            <div class="counter">
                {{cardDeck.whites.length}}
            </div>
        </div>
        <div class="cardgrid">
            <whitecard *ngFor="let wc of cardDeck.whites" [whiteCard]="wc" [showEditButtons]="false"></whitecard>
        </div>
    </div>

    <div *ngIf="loading" id="spinner-overlay">
        <mat-progress-spinner
            id="spinner"
            color="#2196f3"
            mode="indeterminate">
        </mat-progress-spinner>
    </div>
</div>