<div id="help-content">
    <mat-card>
        <div id="help-title">
            Help
        </div>
        <div id="help-text">
            If you need help using CRCast or have any problems you might find a solution to your problem here.<br/>
            If you have no luck finding a solution to your problem you can try asking on our <a href="https://discord.gg/2Zyyh7e" target="_blank">Discord</a>.<br/>
            <br/>
            If you want to report a bug or a problem you can either do it on our <a href="https://discord.gg/2Zyyh7e" target="_blank">Discord</a> or send an E-Mail to the following E-Mail address:<br/>
            <a href="mailto:gitlab+root-crcast-38-issue-@clrtd.com">gitlab+root-crcast-38-issue-@clrtd.com</a><br/>

        </div>
        <div id="help-title">
            (F)AQ
        </div>
        <div id="help-text">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            How do i use decks in a game?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        On the CLRTD Xyzzy server you can use the old Cardcast commands:
                    </p>
                    <code>/addcardcast XXXXX ...</code><br/>
                    <code>/removecardcast XXXXX ...</code><br/>
                    <code>/listcardcast</code><br/>
                    <br/>
                    <p>
                        You have to be the creator/host of the lobby for these commands to work.<br/>
                        These commands work for <a href="https://xyzzy.clrtd.com" target="_blank">https://xyzzy.clrtd.com</a>.
                        On other servers this may not be possible, but decks can also be added to a game through JSON Import if supported.<br/>
                    </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            How do i create a deck?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        To create a deck you have to have a CRCast account and have your E-Mail address activated.<br/>
                        Afterwards, go to your decks by clicking on your username in the top right corner and then on "My Account".<br/>
                        In the top-left corner there should be a button labeled "New Deck". This opens the deck editor where you can set a name, a description and add cards.
                    </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            I updated my deck, but changes aren't appearing in Xyzzy
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        Pretend you're Xyzzy caches decks for 15 minutes after use. Changes to a deck might therefore not appear directly after you updated your deck on CRCast.
                    </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Why does it take so long before I can use my deck?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        For safety reasons we have decided to check decks for cards which could cause problems. Since we have to do this manually it could take up to 24h, so please be patient.<br/>
                        If you want to speed up the process, add a language tag to your deck and avoid using many cards with lots and lots of text.
                    </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            I uploaded a card pack and it's not all in the game
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        It's likely that certain cards were rejected by moderators. Our reasons for this could be that the card was deemed too vulgar, explicit, or offensive for the game, or the card(s) could get the site into legal trouble. (Threats that could be perceived as legitimate). The cards could also have been considered spam and removed from the deck.
                        The Discord server may be used for disputes, but our moderators may not remember exact cards or decks as there is a reasonable volume of new decks we process.
                    </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            I am having issues with the CrCast JSON Uploader
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        Check if your JSON is in a format supported by the JSON Uploader. The JSON Uploader currently supports the following formats (CSV coming soon™):<br/>
                        <br/>
                        - the all bad cards cardcast exporter<br/>
                        - the all bad cards exporter for the custom decks on their site<br/>
                        - and the json format of devgianlu's Android App<br/>
                        - Many Decks from Massive Decks<br/>
                        <br/>
                        New supported formats may be added in the future.<br/>
                        If you need another format supported, you can contact us on Discord or send a feature-request to the mail above.
                    </p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
<!--         <div id="help-title">
            Guides
        </div>
        <div id="help-text">

        </div> -->
    </mat-card>
</div>