import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { TaskInfo } from '../Classes';

@Component({
  selector: 'app-admin-tasks',
  templateUrl: './admin-tasks.component.html',
  styleUrls: ['./admin-tasks.component.scss']
})
export class AdminTasksComponent implements OnInit {

  taskInfo: TaskInfo = {} as TaskInfo;
  timer: any;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.loadTasks();
        }, 1000);
        this.loadTasks();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  loadTasks() {
    this.api.adminGetTasks().then((tasks) => {
      this.taskInfo = tasks;
    }).catch((reason) => {

    });
  }

  startTask(task: string) {
    this.api.adminStartTask(task).then((tasks) => {
      this.loadTasks();
    }).catch((reason) => {

    });
  }

}
