export var languages: Language[] = [
    {displayName: 'Other', langCode: '-'},
    {displayName: 'English', langCode: 'en'},
    {displayName: 'German', langCode: 'de'},
    {displayName: 'French', langCode: 'fr'},
    {displayName: 'Spanish', langCode: 'es'},
    {displayName: 'Italian', langCode: 'it'},
    {displayName: 'Polish', langCode: 'pl'}
  ];

export class WhiteCard implements Serializable {
    id: number = -1;
    text: string = "";
    createdate: Date = null;
    state: number = 2;
    botstate: number = 2;

    constructor(info: any = {}) {
        this.deserialize(info);
    }

    serialize(): any {
        var obj: any = {};

        obj.id = this.id;
        obj.text = this.text;
        obj.createdate = this.createdate;
        obj.state = this.state;
        obj.botstate = this.botstate;

        return obj;
    }

    deserialize(data: any) {
        if (data.id != null) this.id = data.id;
        if (data.text != null) this.text = data.text;
        if (data.createdate != null) this.createdate = new Date(data.createdate);
        if (data.state != null) this.state = data.state;
        if (data.botstate != null) this.botstate = data.botstate;
    }
}

export class BlackCard implements Serializable {
    id: number = -1;
    text: string = "";
    createdate: Date = null;
    state: number = 2;
    botstate: number = 2;

    constructor(info: any = {}) {
        this.deserialize(info);
    }

    getPick() {
        const re = / ?[_]+ ?/g
        return Math.max(((this.text || '').match(re) || []).length, 1);
    }

    serialize(): any {
        var obj: any = {};

        obj.id = this.id;
        obj.text = this.text;
        obj.createdate = this.createdate;
        obj.state = this.state;
        obj.botstate = this.botstate;

        return obj;
    }

    deserialize(data: any) {
        if (data.id != null) this.id = data.id;
        if (data.text != null) this.text = data.text;
        if (data.createdate != null) this.createdate = new Date(data.createdate);
        if (data.state != null) this.state = data.state;
        if (data.botstate != null) this.botstate = data.botstate;
    }
}

export class CardDeck implements Serializable {
    id: number = -1;
    deckcode: string = "";
    name: string = "";
    description: string = "";
    language: Language = languages[0];
    whiteCount: number = 0;
    blackCount: number = 0;
    blacks: BlackCard[] = [];
    whites: WhiteCard[] = [];
    createdate: Date = new Date();
    state: number = 2;
    private: boolean = false;
    nsfw: number = 0;
    locked: boolean = false;
    moderationLock: boolean = false;
    moderationNotes: string = "";
    featured: boolean = false;

    constructor(info: any = {}) {
        this.deserialize(info);
    }

    descHTML(): string {
        return this.description.replace("\\\n", "</br>");
    }

    getStateColor() {
        if (this.deckcode.length != 5) {
            return "#607d8b";
        }

        if (this.locked) {
            return "#607d8b";
        }

        if (this.state == 0) {
            return "#f44336"
        } else if (this.state == 3) {
            return "#00bcd4"
        } else if (this.state == 2) {
            return "#ffc107"
        } else if (this.state == 1) {
            var rejected = this.rejectedCount();
            var pending = this.pendingCount();
            if (rejected > 0) {
                return "#f44336";
            } else if (pending > 0) {
                return "#ffc107";
            } else {
                return "#4caf50";
            }
        }
    }

    getStateText() {
        if (this.deckcode.length != 5) {
            return "Autosave";
        }

        if (this.state == 0) {
            return "Deck Rejected"
        } else if (this.state == 3) {
            return "Unpublished"
        } else if (this.state == 2) {
            return "Pending"
        } else if (this.state == 1) {
            var rejected = this.rejectedCount();
            var pending = this.pendingCount();
            if (rejected > 0) {
                return rejected + " Cards rejected";
            } else if (pending > 0) {
                return pending + " Cards pending";
            } else {
                return "Deck accepted";
            }
        }
    }

    getStateIcon() {
        if (this.deckcode.length != 5) {
            return "";
        }

        if (this.state == 0) {
            return "close"
        } else if (this.state == 3) {
            return "unpublished"
        } else if (this.state == 2) {
            return "help_outline"
        } else if (this.state == 1) {
            var rejected = this.rejectedCount();
            var pending = this.pendingCount();
            if (rejected > 0) {
                return "cancel_presentation";
            } else if (pending > 0) {
                return "quiz";
            } else {
                return "check";
            }
        }
    }

    rejectedCount() {
        var count = 0;

        this.blacks.forEach(black => {
            if (black.state == 0) count++;
        });

        this.whites.forEach(white => {
            if (white.state == 0) count++;
        });

        return count;
    }

    pendingCount() {
        var count = 0;

        this.blacks.forEach(black => {
            if (black.state == 2) count++;
        });

        this.whites.forEach(white => {
            if (white.state == 2) count++;
        });

        return count;
    }

    serialize(): any {
        var obj: any = {};

        obj.deckcode = this.deckcode;
        obj.name = this.name;
        obj.description = this.description;
        obj.language = this.language.langCode;

        obj.blacks = [];
        this.blacks.forEach(black => {
            obj.blacks.push(black.serialize());
        });

        obj.whites = [];
        this.whites.forEach(white => {
            obj.whites.push(white.serialize());
        });

        obj.createdate = this.createdate;
        obj.state = this.state;
        obj.private = this.private;
        obj.nsfw = this.nsfw;
        obj.locked = this.locked;
        obj.moderationLock = this.moderationLock;
        obj.moderationNotes = this.moderationNotes;

        obj.featured = this.featured;

        return obj;
    }

    deserialize(data: any) {
        if (data.deckcode != null) this.deckcode = data.deckcode;
        if (data.name != null) this.name = data.name;
        if (data.description != null) this.description = data.description;

        if (data.language != null) languages.forEach(lang => {
            if (lang.langCode == data.language) {
                this.language = lang;
            }
        });

        if (data.blackCount != null) this.blackCount = data.blackCount;

        if (data.blacks != null) data.blacks.forEach(black => {
            this.blacks.push(new BlackCard(black));
        });

        if (data.whiteCount != null) this.whiteCount = data.whiteCount;
        if (data.whites != null) data.whites.forEach(white => {
            this.whites.push(new WhiteCard(white));
        });

        if (data.createdate != null) this.createdate = new Date(data.createdate);
        if (data.state != null) this.state = data.state;
        if (data.private != null) this.private = data.private;
        if (data.nsfw != null) this.nsfw = data.nsfw;
        if (data.locked != null) this.locked = data.locked;
        if (data.moderationLock != null) this.moderationLock = data.moderationLock;
        if (data.moderationNotes != null) this.moderationNotes = data.moderationNotes;

        if (data.featured != null) this.featured = data.featured;
    }
}

export class User implements Serializable {
    id: number = -1;
    username: string = "";
    email: string = "";
    activated: boolean = false;
    banned: boolean = false;
    role: Role = Role.Guest;
    registerdate: Date = null;
    favorites: string[] = [];
    deletionQueued: Date = null;

    constructor(info: any = {}) {
        this.deserialize(info);
    }

    hasRole(role: Role) {
        return Role.hasAccess(this.role, role);
    }

    serialize(): any {
        var obj: any = {};

        obj.id = this.id;
        obj.username = this.username;
        obj.email = this.email;
        obj.activated = this.activated;
        obj.banned = this.banned;
        obj.role = this.role.name;
        obj.registerdate = this.registerdate;
        obj.favorites = this.favorites;
        obj.deletionQueued = this.deletionQueued;

        return obj;
    }

    deserialize(data: any) {
        if (data.id != null) this.id = data.id;
        if (data.username != null) this.username = data.username;
        if (data.email != null) this.email = data.email;
        if (data.activated != null) this.activated = data.activated;
        if (data.banned != null) this.banned = data.banned;
        if (data.role != null) this.role = Role.parseRole(data.role);
        if (data.registerdate != null) this.registerdate = new Date(data.registerdate);
        if (data.favorites != null) this.favorites = data.favorites;
        if (data.deletionQueued != null) this.deletionQueued = new Date(data.deletionQueued);
    }
}

export class Role {
    static get Guest() { return new Role(0, "guest", "#9e9e9e", "person_off"); }
    static get Normal() { return new Role(1, "normal", "#4caf50", "person"); }
    static get Moderator() { return new Role(2, "moderator", "#00bcd4", "person_search"); }
    static get Administrator() { return new Role(3, "administrator", "#ff5722", "manage_accounts"); }

    name = "";
    permLvl = -1;
    color = "";
    icon = "";

    constructor(permLvl, name, color, icon) {
        this.permLvl = permLvl;
        this.name = name;
        this.color = color;
        this.icon = icon;
    }

    getDisplayName(): string {
        return this.name.charAt(0).toUpperCase() + this.name.substr(1).toLowerCase();
    }

    static parseRole(rolename) {
        switch (rolename) {
            case "normal":
                return Role.Normal;
            case "moderator":
                return Role.Moderator;
            case "administrator":
                return Role.Administrator;
            default:
                return Role.Guest;
        }
    }

    static hasAccess(role, perm) {
        return role.permLvl >= perm.permLvl;
    }
}

export class CREvent implements Serializable {
    id: number = -1;

    type: string = "";
    date: Date = undefined;
    origin: string = "";
    deckcode: string = "";
    username: string = "";
    changekey: string = "";

    constructor(info: any = {}) {
        this.deserialize(info);
    }

    serialize(): any {
        var obj: any = {};

        obj.id = this.id;
        obj.type = this.type;
        obj.date = this.date;
        obj.origin = this.origin;
        obj.deckcode = this.deckcode;
        obj.username = this.username;
        obj.changekey = this.changekey;

        return obj;
    }

    deserialize(data: any) {
        if (data.id != null) this.id = data.id;
        if (data.type != null) this.type = data.type;
        if (data.date != null) this.date = new Date(data.date);
        if (data.origin != null) this.origin = data.origin;
        if (data.deckcode != null) this.deckcode = data.deckcode;
        if (data.username != null) this.username = data.username;
        if (data.changekey != null) this.changekey = data.changekey;
    }
}

export interface Serializable {
    serialize(): any;
    deserialize(data: any);
}

export interface Language {
    displayName: string;
    langCode: string;
}

export class Limits implements Serializable {
    deckLimit: number = -1;
    blacksLimit: number = -1;
    whitesLimit: number = -1;

    constructor(info: any = {}) {
        this.deserialize(info);
    }

    checkDeckLimit(decks: number) {
        return this.deckLimit == -1 || decks <= this.deckLimit;
    }

    checkBlackLimit(blacks: number) {
        return this.blacksLimit == -1 || blacks <= this.blacksLimit;
    }

    checkWhiteLimit(whites: number) {
        return this.whitesLimit == -1 || whites <= this.whitesLimit;
    }

    serialize(): any {
        var obj: any = {};

        obj.deckLimit = this.deckLimit;
        obj.blacksLimit = this.blacksLimit;
        obj.whitesLimit = this.whitesLimit;

        return obj;
    }

    deserialize(data: any) {
        if (data.deckLimit != null) this.deckLimit = data.deckLimit;
        if (data.blacksLimit != null) this.blacksLimit = data.blacksLimit;
        if (data.whitesLimit != null) this.whitesLimit = data.whitesLimit;
    }
}

export interface TaskInfo {
    tasks: {id: string, name: string, schedule: string, nextDate: string, running: boolean}[];
    availableTasks: string[]
}