<div id="wrapper">
    <div id="apidoc-title">
        API Documentation
    </div>

    <div id="apidoc-description">
        Currently there are 3 endpoints available for public use.<br/>
        These endpoints are similar to those of the CardCast API.<br/>
        To test our API endpoints you can use the deck <code>VD5MM</code>.<br/>
    </div>

    <div id="apidoc-endpoints">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Get Card Deck Information
                </mat-panel-title>
                <mat-panel-description>
                    GET https://api.crcast.cc/v1/cc/decks/&lt;deckcode&gt;/
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="apidoc-ep-description">
                <code class="white">GET https://api.crcast.cc/v1/cc/decks/&lt;deckcode&gt;/</code>
                Use this endpoint to get deck information like Name and Decription.<br/>
                The deckcode is always a 5-digit code containing uppercase letters and numbers.<br/>
                <br/>
                A result of this GET request should look like this:<br/>
                <pre><code [highlight]="deckinfo" [lineNumbers]="true"></code></pre>
            </div>

        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Get Card Deck Cards
                </mat-panel-title>
                <mat-panel-description>
                    GET https://api.crcast.cc/v1/cc/decks/&lt;deckcode&gt;/cards/
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="apidoc-ep-description">
                <code class="white">GET https://api.crcast.cc/v1/cc/decks/&lt;deckcode&gt;/cards</code>
                Use this endpoint to get the black and white cards from a carddeck.<br/>
                <blockquote>
                    This request will track the usage of the requested deck, which means it will count how often this deck has been requested by this endpoint.<br/>
                    Usage Tracking can be disabled by adding the query parameter <code class="white inline">?noTrackUsage=true</code> to the request URL.
                </blockquote>
                Every Call or Response card consists of a "text" array with the content in form of a string.<br/>
                White cards (Reponses) always have only one element in the text array.<br/>
                Black cards (Calls) are being split at any underscore (_) and each element is a separate string in the text array.<br/>
                To get the amount of white cards each player has to pick for a black card, you simply have to count the amount of strings in the text array and subtract 1:<br/><br/>
                <code class="white">
                    pick = Amount of Strings in "text" - 1
                </code>
                Here are the example deck's black cards to compare to the API endpoint result:<br/>
                <img alt="Examples of Black Cards with Underscores for blank spots" src="assets/images/apidoc-cards-blacks-example.png"/><br/>
                A result of this GET request should look like this:<br/>
                <pre><code [highlight]="deckcards" [lineNumbers]="true"></code></pre>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Get Card Deck Information and Cards in one
                </mat-panel-title>
                <mat-panel-description>
                    GET https://api.crcast.cc/v1/cc/decks/&lt;deckcode&gt;/all/
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="apidoc-ep-description">
                <code class="white">GET https://api.crcast.cc/v1/cc/decks/&lt;deckcode&gt;/all</code>
                Use this endpoint to get the cards and information from a carddeck in one request.<br/>
                <blockquote>
                    This request will track the usage of the requested deck, which means it will count how often this deck has been requested by this endpoint.<br/>
                    Usage Tracking can be disabled by adding the query parameter <code class="white inline">?noTrackUsage=true</code> to the request URL.
                </blockquote>
                This is essentially the first two requests combined in one.<br/>
                (If youre devgianlu you will love this one)<br/>
                A result of this GET request should look like this:<br/>
                <pre><code [highlight]="deckall" [lineNumbers]="true"></code></pre>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    </div>
</div>