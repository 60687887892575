import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-activator',
  templateUrl: './activator.component.html',
  styleUrls: ['./activator.component.scss']
})
export class ActivatorComponent implements OnInit {
  email: string = "";
  error: string = "";
  activating: boolean = true;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('code')) {
      this.api.activateMail(urlParams.get('code')).then((data) => {
        this.email = data.email;
        this.activating = false;

        this.api.getUserInfo().then((data) => {
          this.api.setUser(data);
        }).catch((err) => {

        });
    }).catch((err) => {
        this.error = err.error.message;
        this.activating = false;
        console.log(err);
      });
    }
  }

  backToHome() {
    window.history.pushState({}, document.title, "/");
    location.pathname = "/";
  }
}
