import { Component } from '@angular/core';
import { ApiService } from '../api.service';
import { UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reset-pw',
  templateUrl: './reset-pw.component.html',
  styleUrls: ['./reset-pw.component.scss']
})
export class ResetPWComponent {

  password1 = new UntypedFormControl('', [Validators.required, this.validatePassword.bind(this)]);
  password2 = new UntypedFormControl('', [Validators.required, this.samePassword.bind(this)]);

  requesting: boolean = false;
  completed: boolean = false;

  constructor(private api: ApiService, private _snackBar: MatSnackBar) { }

  validatePassword(control: AbstractControl): {[key: string]: any} | null  {
    let match = control.value.match(/[A-Za-z0-9_\-!§$%&?^+*#\':;,.]+/i);
    if (match == null || match[0] != control.value) {
      return {'invalidPassword': true};
    }
    return null;
  }

  samePassword(control: AbstractControl): {[key: string]: any} | null  {
    if (this.password1.value != control.value) {
      return {'notsame': true};
    }
    return null;
  }

  getErrorMessage(element) {
    if (element == "password1") {
      if (this.password1.hasError('required')) {
        return 'You must enter a Password';
      } else if (this.password1.hasError('invalidPassword')) {
        return 'Invalid Characters';
      }
    } else if (element == "password2") {
      if (this.password2.hasError('required')) {
        return 'You must enter a Password';
      } else if (this.password2.hasError('notsame')) {
        return 'Passwords do not match';
      }
    }
  }

  sendRequest() {
    if (this.password1.invalid || this.password2.invalid) return;

    this.requesting = true;

    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('code')) {
      this.api.resetPassword(urlParams.get('code'), this.password1.value).then((result) => {
        this.requesting = false;
        this.completed = true;
      }).catch((reason) => {
        this.requesting = false;
        console.log(reason);
        this._snackBar.open(reason.error.message, null, {duration: 2000});
      });
    }
  }

  backToHome() {
    window.history.pushState({}, document.title, "/");
    location.pathname = "/";
  }
}
