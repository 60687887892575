<div id="decklist-content">
    <div id="decklist-filterbar">
        <div id="filterbar-left">
            <mat-form-field appearance="outline" id="decklist-searchbox" class="filter-field">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Name or Code" [(ngModel)]="filtertext" [ngModelOptions]="{standalone: true}" (keydown.enter)="applyFilters()">
                <mat-icon class="noselect" matSuffix>title</mat-icon>
            </mat-form-field>


            <mat-form-field appearance="outline" class="filter-field">
                <mat-label>Language</mat-label>
                <mat-select [(ngModel)]="filterLanguage" multiple>
                    <mat-option *ngFor="let lang of langs()" [value]="lang.langCode">{{lang.displayName}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-slide-toggle id="decklist-sfw" class="filter-field" [(ngModel)]="filterSFW" (change)="applyFilters()">Only SFW</mat-slide-toggle>

        </div>

        <div id="filterbar-right">
            <button mat-icon-button [disableRipple]="true" aria-label="Sort" style="width: 100px; margin-right: 69px;" (click)="selectSort.open()">
                <mat-icon>sort</mat-icon>

                <mat-select #selectSort [disableRipple]="true" class="selectSort" [(ngModel)]="sort" (selectionChange)="resort($event)">
                    <mat-option value="none">None</mat-option>
                    <mat-option value="featured">Featured</mat-option>
                    <mat-option value="popular">Popular</mat-option>
                    <mat-option value="popularLM">Popular Last Month</mat-option>
                    <mat-option value="new">Newest</mat-option>
                </mat-select>
            </button>

            <button mat-icon-button aria-label="Filter" (click)="applyFilters()">
                <mat-icon>filter_alt</mat-icon>
            </button>

            <button mat-icon-button aria-label="Clear" [disabled]="!isfilter" (click)="clearFilters()">
                <mat-icon>clear</mat-icon>
            </button>
        </div>

    </div>
    <div id="decklist-listwrapper">
        <empty-state *ngIf="decks != null && decks.length == 0 && !loading" topMsg="No Decks found" btnText="Clear Filters" (btnClicked)="clearFilters()"></empty-state>
        <div class="deck-item" *ngFor="let deck of decks">
            <div class="deck-name">
                {{deck.name}}
            </div>

            <mat-icon *ngIf="deck.nsfw > 0" class="deck-icon deck-nsfw noselect" matTooltip="{{deck.nsfw == 1? 'NSFW' : 'Forced NSFW'}}" [style.color]="deck.nsfw == 1? '#4caf5090' : '#f4433690'" svgIcon="nsfw"></mat-icon>
            <div class="deck-desc">
                {{deck.description | slice:0:100}}
            </div>
            <div class="deck-code">
                {{deck.deckcode}}
            </div>

            <div class="deck-registered" matTooltip="Creation Date">
                {{deck.createdate | date}}
            </div>

            <div class="deck-lang" matTooltip="{{deck.language.displayName}}">
                {{deck.language.langCode}}
            </div>

            <mat-icon class="deck-icon noselect" matTooltip="{{deck.private? 'Private' : 'Public'}}" [style.color]="deck.private? '#ffffff70' : '#ffffff70'">{{deck.private? 'lock' : 'public'}}</mat-icon>
            <mat-icon class="deck-icon noselect" matTooltip="{{deck.locked? 'Locked' : 'Unlocked'}}" [style.color]="deck.locked? '#ffffff70' : '#ffffff70'">{{deck.locked? 'lock' : 'lock_open'}}</mat-icon>
            <mat-icon class="deck-icon noselect" matTooltip="{{deck.featured? 'Featured' : 'Not Featured'}}" [style.color]="deck.featured? '#4caf50' : '#00000060'">{{deck.featured? 'check' : 'close'}}</mat-icon>
            <mat-icon class="deck-icon noselect" matTooltip="{{deck.getStateText()}}" [style.color]="deck.getStateColor()">{{deck.getStateIcon()}}</mat-icon>

            <button class="deck-more" mat-icon-button aria-label="Menu" [matMenuTriggerFor]="deckmenu" [matMenuTriggerData]="{deck: deck}">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
        <mat-menu #deckmenu="matMenu">
            <ng-template matMenuContent let-deck="deck">
                <button (click)="setDeckFeatured(deck)" mat-menu-item> {{deck.featured? 'Unfeature' : 'Feature'}}</button>
                <button (click)="setDeckLocked(deck)" mat-menu-item> {{deck.locked? 'Unlock' : 'Lock'}}</button>
                <button mat-menu-item [matMenuTriggerFor]="nsfw" [matMenuTriggerData]="{deck: deck}">Set NSFW</button>
                <button mat-menu-item [matMenuTriggerFor]="languages" [matMenuTriggerData]="{deck: deck}">Set Language</button>
                <button [disabled]="true" mat-menu-item [style.color]="false? '#f44336' : ''">Delete</button>
            </ng-template>
        </mat-menu>
        <mat-menu #nsfw="matMenu">
            <ng-template matMenuContent let-deck="deck">
                <button [disabled]="deck.nsfw == 0" mat-menu-item (click)="setDeckNSFW(deck, 0)">Not NSFW</button>
                <button [disabled]="deck.nsfw == 1" mat-menu-item (click)="setDeckNSFW(deck, 1)">NSFW</button>
                <button [disabled]="deck.nsfw == 2" mat-menu-item (click)="setDeckNSFW(deck, 2)">Force NSFW</button>
            </ng-template>
        </mat-menu>
        <mat-menu #languages="matMenu">
            <ng-template matMenuContent let-deck="deck">
                <button *ngFor="let lang of langs()" [disabled]="deck.language.langCode == lang.langCode" mat-menu-item (click)="setDeckLanguage(deck, lang)">{{lang.displayName}}</button>
            </ng-template>
        </mat-menu>
    </div>
    <mat-paginator id="paginator" [length]="deckCount"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
            [pageIndex] = "currentPage"
            (page)="switchPage($event); loadNew();"
            aria-label="Select page">
    </mat-paginator>

    <div *ngIf="loading" class="spinner-overlay">
        <mat-progress-spinner
            class="spinner"
            color="#2196f3"
            mode="indeterminate">
        </mat-progress-spinner>
    </div>
</div>












