<div id="userlist-content">
    <div id="userlist-filterbar">
        <div id="filterbar-left">
            <mat-form-field appearance="outline" id="userlist-searchbox" class="filter-field">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Username or E-Mail" [(ngModel)]="filtertext" [ngModelOptions]="{standalone: true}" (keydown.enter)="applyFilters()">
                <mat-icon class="noselect" matSuffix>title</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" id="userlist-rolefilter" class="filter-field">
                <mat-label>Roles</mat-label>
                <mat-select [(value)]="filterRoles" (selectionChange)="roleFilterChanged()" multiple>
                    <mat-select-trigger>
                        <mat-icon *ngFor="let role of filterRoles" class="user-icon noselect" matTooltip="{{Role.parseRole(role).getDisplayName()}}" [style.color]="Role.parseRole(role).color">{{Role.parseRole(role).icon}}</mat-icon>
                    </mat-select-trigger>
                    <mat-option [value]="Role.Administrator.name">{{Role.Administrator.getDisplayName()}}</mat-option>
                    <mat-option [value]="Role.Moderator.name">{{Role.Moderator.getDisplayName()}}</mat-option>
                    <mat-option [value]="Role.Normal.name">{{Role.Normal.getDisplayName()}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-checkbox class="filter-field"
                    [checked]="filterActive >= 1"
                    [indeterminate]="filterActive == 2"
                    (change)="switchActive()">
            {{(filterActive == 0)? "Not Active" : (filterActive == 1)? "Active" : "(Not) Active"}}
            </mat-checkbox>

            <mat-checkbox class="filter-field"
                    [checked]="filterBanned >= 1"
                    [indeterminate]="filterBanned == 2"
                    (change)="switchBanned()">
            {{(filterBanned == 0)? "Not Banned" : (filterBanned == 1)? "Banned" : "(Not) Banned"}}
            </mat-checkbox>
        </div>

        <div id="filterbar-right">
            <button mat-icon-button [disableRipple]="true" aria-label="Sort" style="width: 100px; margin-right: 69px;" (click)="selectSort.open()">
                <mat-icon>sort</mat-icon>

                <mat-select #selectSort [disableRipple]="true" class="selectSort" [(ngModel)]="sort" (selectionChange)="resort($event)">
                    <mat-option value="none">None</mat-option>
                    <mat-option value="active">Activity</mat-option>
                    <mat-option value="new">Newest</mat-option>
                </mat-select>
            </button>

            <button mat-icon-button aria-label="Filter" (click)="applyFilters()">
                <mat-icon>filter_alt</mat-icon>
            </button>

            <button mat-icon-button aria-label="Clear" [disabled]="!isfilter" (click)="clearFilters()">
                <mat-icon>clear</mat-icon>
            </button>
        </div>

    </div>
    <div id="userlist-listwrapper">
        <empty-state *ngIf="users != null && users.length == 0 && !loading" topMsg="No User found" btnText="Clear Filters" (btnClicked)="clearFilters()"></empty-state>
        <div class="user-item" *ngFor="let user of users">
            <mat-icon *ngIf="user.deletionQueued != null" class="user-icon noselect" matTooltip="Deletion Queued" [style.color]="'#f44336'">delete</mat-icon>

            <div class="user-name">
                {{user.username}}
            </div>
            <div class="user-email">
                {{user.email}}
            </div>

            <div class="user-registered" matTooltip="Register Date">
                {{user.registerdate | date}}
            </div>

            <mat-icon class="user-icon noselect" matTooltip="{{user.role.getDisplayName()}}" [style.color]="user.role.color">{{user.role.icon}}</mat-icon>
            <mat-icon class="user-icon noselect" matTooltip="{{user.activated? 'Activated' : 'Not Activated'}}" [style.color]="user.activated? '#4caf50' : '#f44336'">{{user.activated? 'check' : 'close'}}</mat-icon>
            <mat-icon class="user-icon noselect" matTooltip="{{user.banned? 'Banned' : 'Not Banned'}}" [style.color]="user.banned? '#f44336' : '#4caf50'">{{user.banned? 'close' : 'check'}}</mat-icon>
            <button class="user-more noselect" mat-icon-button aria-label="Menu" [matMenuTriggerFor]="usermenu" [matMenuTriggerData]="{user: user}">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
        <mat-menu #usermenu="matMenu">
            <ng-template matMenuContent let-user="user">
                <button (click)="setUserActivated(user)" mat-menu-item> {{user.activated? 'Deactivate' : 'Activate'}}</button>
                <button (click)="setUserBanned(user)" mat-menu-item> {{user.banned? 'Unban' : 'Ban'}}</button>
                <button (click)="openChangeEmailDialog(user)" mat-menu-item>Set E-Mail</button>
                <button (click)="openChangePasswordDialog(user)" mat-menu-item>Set Password</button>
                <button [disabled]="true" mat-menu-item>Show Decks</button>
                <button mat-menu-item [matMenuTriggerFor]="roles" [matMenuTriggerData]="{user: user}">Set Role</button>
                <button (click)="setUserDeletion(user)" mat-menu-item [style.color]="(user.deletionQueued)? '#4caf50' : '#f44336'">{{user.deletionQueued? 'Cancel Deletion' : 'Queue Deletion'}}</button>
            </ng-template>
        </mat-menu>
        <mat-menu #roles="matMenu">
            <ng-template matMenuContent let-user="user">
                <button [disabled]="user.role.name == Role.Normal.name" mat-menu-item (click)="setUserRole(user, Role.Normal)">{{Role.Normal.getDisplayName()}}</button>
                <button [disabled]="user.role.name == Role.Moderator.name" mat-menu-item (click)="setUserRole(user, Role.Moderator)">{{Role.Moderator.getDisplayName()}}</button>
                <button [disabled]="user.role.name == Role.Administrator.name" mat-menu-item (click)="setUserRole(user, Role.Administrator)">{{Role.Administrator.getDisplayName()}}</button>
            </ng-template>
        </mat-menu>
    </div>
    <mat-paginator id="paginator" [length]="userCount"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
            [pageIndex] = "currentPage"
            (page)="switchPage($event); loadNew();"
            aria-label="Select page">
    </mat-paginator>

    <div *ngIf="loading" class="spinner-overlay">
        <mat-progress-spinner
            class="spinner"
            color="#2196f3"
            mode="indeterminate">
        </mat-progress-spinner>
    </div>
</div>












