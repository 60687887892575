<div class="category noselect">
    <div *ngIf="loading" class="spinner-overlay">
        <mat-progress-spinner
          class="spinner"
          color="#2196f3"
          mode="indeterminate">
      </mat-progress-spinner>
    </div>
    <div class="cat-title">
        {{title}}
    </div>
    <div class="cat-list">

        <comp-carddeck-card class="home-deckcard" *ngFor="let deck of cardDecks" [cardDeck]="deck"
            [showDeckState]="false" [showCopyButtons]="true" [routerLink]="['/deck', deck.deckcode]"></comp-carddeck-card>

    </div>
</div>