import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit {

  changelog: any = [
    {
      version: "0.5.1",
      changes: [
        "Added a changelog page (you don't say)",
        "Fixed some smaller frontend and backend stuff",
        "Fixed API Crashes",
        "Made every™ page mobile friendly (first issue ever on our issue tracker lol)",
        "Fixed newlines (\\n) not being displayed properly on cards",
        "New popular category on home page for popular last month",
        "Errors are schown in the frontend now (including a Trace ID, which helps us to help you)",
      ]
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
