import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service';
import { CardDeck } from '../Classes';
import { showErrorSnackbar } from '../utils';

@Component({
  selector: 'app-categorylist',
  templateUrl: './categorylist.component.html',
  styleUrls: ['./categorylist.component.scss']
})
export class CategorylistComponent implements OnInit {
  @Input() title: string;
  @Input() sort: string;

  cardDecks: CardDeck[];
  loading: boolean = true;

  constructor(private api: ApiService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.api.getCardDecksSort(0, 20, this.sort).then((data) => {
      this.cardDecks = data.decks;
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      showErrorSnackbar(err, this._snackBar);
    });
  }
}
