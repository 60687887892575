<div class="toolbar">
    <div class="toolbar-left">
      <button class="notmobile" mat-button [routerLink]="['/api']">API</button>
      <button class="notmobile" mat-button [routerLink]="['/help']">Help</button>
      <button class="mobile" mat-icon-button [routerLink]="['/help']"><mat-icon>contact_support</mat-icon></button>
      <button class="notmobile" mat-button [routerLink]="['/changelog']">Changelog</button>
      <button class="mobile" mat-icon-button [routerLink]="['/changelog']"><mat-icon>history</mat-icon></button>
    </div>
    <div class="toolbar-center notmobile" [routerLink]="['/']">
        CRCAST
        <div *ngIf="feVersion.branch != 'master' && feVersion.branch != '%BRANCH%'" id="crcast-version">
          {{feVersion.branch}}<br/>
          {{feVersion.commit}}
        </div>
    </div>
    <div class="toolbar-center mobile">

    </div>

    <div class="toolbar-right">
        <button class="{{loggedin()? 'mat-stroked-button' : 'mat-button'}} topbar-button notmobile" (click)="openloginform = !openloginform;">{{loggedin()? username() : "Login"}}</button>
        <button mat-icon-button class="mobile" color="white" aria-label="Account" (click)="openloginform = !openloginform;">
          <mat-icon>account_circle</mat-icon>
        </button>
        <div [ngClass]="{'open': openloginform, 'closed': !openloginform}" id="loginform">
            <div *ngIf="!loggedin()">

              <div *ngIf="sending" id="spinner-overlay">
                <mat-progress-spinner
                  id="spinner"
                  color="#2196f3"
                  mode="indeterminate">
                </mat-progress-spinner>
              </div>

              <form [formGroup]="loginform" (ngSubmit)="login()">
                <div id="loginform-wrapper">
                  <mat-form-field class="loginformfield">
                    <input id="input_username" matInput placeholder="{{ 'menu.login.username' | translate }}" formControlName="userident" required>
                    <mat-error *ngIf="userident.invalid">{{getErrorMessage('userident')}}</mat-error>
                  </mat-form-field>

                  <mat-form-field class="loginformfield">
                    <input id="input_password" matInput placeholder="{{ 'menu.login.password' | translate }}" type="password" formControlName="password" required>
                    <mat-error *ngIf="password.invalid">{{getErrorMessage('password')}}</mat-error>
                  </mat-form-field>
                </div>

                <div id="login-error" *ngIf="forminvalid">
                  {{errormessage}}
                </div>

                <div id="loginform-buttons">
                  <div id="forgot-link"><a href="/requestReset">Forgot Password</a></div>
                  <button id="login-button" mat-stroked-button type="submit" [disabled]="!loginform.valid">{{ 'menu.login.loginbutton' | translate }}</button>
                  <button id="register-button" mat-stroked-button type="button" [routerLink]="['/register']" (click)="openloginform = false">{{ 'menu.login.registerbutton' | translate }}</button>
                </div>
              </form>
            </div>
            <div style="line-height: 36px;" *ngIf="loggedin()">
                <div id="loginform-hellomsg">
                    {{ 'menu.login.hello' | translate }} {{username()}}
                </div>

                <div *ngIf="userHasProblems()" id="loginform-infotext">
                  {{userProblemText()}}
                </div>

                <button class="login-profile-button" mat-stroked-button [routerLink]="['/account']" (click)="openloginform = false">My Account</button>
                <button *ngIf="loggedin() && hasRole(Role().Administrator)" class="login-profile-button" mat-stroked-button [routerLink]="['/admin']" (click)="openloginform = false">Administration</button>
                <button class="login-logout-button" mat-stroked-button (click)="logout()">{{ 'menu.login.logout' | translate }}</button>
            </div>
          </div>

        <button class="topbar-button notmobile" mat-button [routerLink]="['/decks']">Browse</button>

        <button mat-icon-button class="mobile" color="white" [routerLink]="['/decks']" aria-label="Browse">
          <mat-icon>view_module</mat-icon>
        </button>

        <button class="topbar-button notmobile" mat-button [routerLink]="['/']">Home</button>

        <button mat-icon-button class="mobile" color="white" [routerLink]="['/']" aria-label="Home">
          <mat-icon>home</mat-icon>
        </button>
    </div>
</div>



