import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../api.service';
import { CardDeck, Language, languages } from '../Classes';
import { showErrorSnackbar } from '../utils';

@Component({
  selector: 'app-admin-decklist',
  templateUrl: './admin-decklist.component.html',
  styleUrls: ['./admin-decklist.component.scss']
})
export class AdminDecklistComponent implements OnInit {

  decks: CardDeck[] = [];
  currentPage = 0;
  pageSize = 25;
  deckCount: number = 0;
  loading: boolean = false;

  filtertext: string = "";
  filterLanguage: string[] = [];
  filterSFW: boolean = false;
  sort: string = "none";
  isfilter: boolean = false;
  showFavorites: boolean = false;

  langs() {
    return languages;
  }

  constructor(private api: ApiService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private _snackBar: MatSnackBar) {
    this.matIconRegistry.addSvgIcon('nsfw', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/nsfw-24px.svg"));
  }

  isLoggedIn() {
    return this.api.isLoggedIn();
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.onApiReady();
    });
  }

  onApiReady() {
    this.loadNew();
  }

  loadNew() {
    if (this.loading) return;

    this.loading = true;
    this.decks = [];

    if (this.isfilter) {
      this.api.getAdminCardDecksSortFilter(this.currentPage, this.pageSize, this.sort, this.filtertext, this.filterSFW, this.filterLanguage).then((data) => {
        this.decks = data.decks;
        this.deckCount = data.count;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        showErrorSnackbar(err, this._snackBar);
      });
    } else {
      this.api.getAdminCardDecksSortFilter(this.currentPage, this.pageSize, this.sort, null, null, null).then((data) => {
        this.decks = data.decks;
        this.deckCount = data.count;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        showErrorSnackbar(err, this._snackBar);
      });
    }
  }

  setDeckFeatured(deck: CardDeck) {
    this.api.adminSetDeckFeatured(deck.deckcode, !deck.featured).then((data) => {
      this.loadNew();
    }).catch((err) => {
      showErrorSnackbar(err, this._snackBar);
    });
  }

  setDeckLocked(deck: CardDeck) {
    this.api.adminSetDeckLocked(deck.deckcode, !deck.locked).then((data) => {
      this.loadNew();
    }).catch((err) => {
      showErrorSnackbar(err, this._snackBar);
    });
  }

  setDeckNSFW(deck: CardDeck, nsfw: number) {
    this.api.adminSetDeckNSFW(deck.deckcode, nsfw).then((data) => {
      this.loadNew();
    }).catch((err) => {
      showErrorSnackbar(err, this._snackBar);
    });
  }

  setDeckLanguage(deck: CardDeck, language: Language) {
    this.api.adminSetDeckLanguage(deck.deckcode, language).then((data) => {
      this.loadNew();
    }).catch((err) => {
      showErrorSnackbar(err, this._snackBar);
    });
  }

  switchPage(event) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  reload() {
    this.loadNew();
  }

  resort(event) {
    this.sort = event.value;
    this.reload();
  }

  applyFilters() {
    this.isfilter = true;
    this.currentPage = 0;
    this.reload();
  }

  clearFilters() {
    this.isfilter = false;
    this.filtertext = "";
    this.filterLanguage = [];
    this.filterSFW = false;
    this.currentPage = 0;
    this.reload();
  }
}
