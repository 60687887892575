<div id="registerform-wrapper">
    <div id="registerform-title">
        Register
    </div>

    <div *ngIf="requesting" id="spinner-overlay">
        <mat-progress-spinner
        id="spinner"
        color="#2196f3"
        mode="indeterminate">
        </mat-progress-spinner>
    </div>

    <div *ngIf="registerComplete" id="registerform-complete">
        <div id="registerform-complete-text">
            You successfully registered as <span id="registerform-complete-user">{{userident.value}}</span>
        </div>

        <div id="registerform-complete-text2">
            We sent you an Activation Link to
        </div>

        <div id="registerform-complete-email">
            {{email.value}}
        </div>

        <button id="registerform-complete-button" mat-flat-button (click)="backToHome()">Back to Home</button>
    </div>

    <form *ngIf="!registerComplete" [formGroup]="registerform" (ngSubmit)="register()">
        <mat-form-field appearance="outline" class="registerform-formfield">
            <mat-label>E-Mail</mat-label>
            <input matInput placeholder="E-Mail" [formControl]="email" (ngModelChange)="emailInUse = false" value="leel@gmail.com">
            <mat-error *ngIf="email.invalid">{{getErrorMessage('email')}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="registerform-formfield">
            <mat-label>Username</mat-label>
            <input matInput placeholder="Username" [formControl]="userident" (ngModelChange)="userInUse = false">
            <mat-error *ngIf="userident.invalid">{{getErrorMessage('userident')}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="registerform-formfield">
            <mat-label>Password</mat-label>
            <input matInput placeholder="Password" [formControl]="password" type="password">
            <mat-error *ngIf="password.invalid">{{getErrorMessage('password')}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="registerform-formfield">
            <mat-label>Confirm Password</mat-label>
            <input matInput placeholder="Password" [formControl]="password2" type="password">
            <mat-error *ngIf="password2.invalid">{{getErrorMessage('password2')}}</mat-error>
        </mat-form-field>

        <button id="register-button" mat-stroked-button type="submit" [disabled]="!registerform.valid || requesting">{{ 'menu.login.registerbutton' | translate }}</button>
    </form>
</div>
