import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service';
import { Role } from '../Classes';
import { showErrorSnackbar } from '../utils';

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss']
})
export class AccountProfileComponent {

  constructor(private api: ApiService, public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  Role() {return Role;}

  user() {
    return this.api.isLoggedIn()? this.api.user : null;
  }

  deletionDate() {
    console.log(this.user().deletionQueued);
    let newDate = new Date(this.user().deletionQueued);
    newDate.setDate( newDate.getDate() + 14 );
    return newDate;
  }

  openDeleteDialog() {
    const dialogRef = this.dialog.open(DeleteDialog, {
      data: {verification: "Delete " + this.user().username}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.api.queueAccountDeletion().then((data) => {
          this._snackBar.open("Your Account has been flagged for deletion!", null, {duration: 5000});
          this.api.getUserInfo().then((data) => {
            this.api.setUser(data);
          }).catch((err) => {
          });
        }).catch((err) => {
          this._snackBar.open("There has been an Error deleting your Account. Please check again", null, {duration: 2000});
        });
      }
    });
  }

  openEmailChangeDialog() {
    const dialogRef = this.dialog.open(EmailChangeDialog, {
      data: {oldmail: this.user().email}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.api.changeMail(result.password, result.email).then((data) => {
          this._snackBar.open("The E-Mail Address for your Account has been changed!", null, {duration: 5000});
          this.api.getUserInfo().then((data) => {
            this.api.setUser(data);
          }).catch((err) => {
          });
        }).catch((err) => {
          this._snackBar.open("There has been an Error changing the E-Mail Address of your Account. Please check again", null, {duration: 2000});
        });
      }
    });
  }

  cancelDeletion() {
    this.api.cancelAccountDeletion().then((data) => {
      this._snackBar.open("Your Account deletion has been cancelled successfully!", null, {duration: 5000});
      this.api.getUserInfo().then((data) => {
        this.api.setUser(data);
      }).catch((err) => {
      });
    }).catch((err) => {
      this._snackBar.open("There has been an Error cancelling your account deletion. Please check again", null, {duration: 2000});
    });
  }

  resendMail() {
    this.api.resendActivationMail().then((data) => {
      this._snackBar.open("The Activation E-Mail has been resent!", null, {duration: 5000});
      this.api.getUserInfo().then((data) => {
        this.api.setUser(data);
      }).catch((err) => {

      });
    }).catch((err) => {
      showErrorSnackbar(err, this._snackBar);
    });
  }
}


@Component({
  selector: 'delete-dialog',
  templateUrl: 'delete-dialog.html',
})
export class DeleteDialog {

  constructor(
    public dialogRef: MatDialogRef<DeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    verification = "";

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'emailchange-dialog',
  templateUrl: 'emailchange-dialog.html',
})
export class EmailChangeDialog {

  constructor(
    public dialogRef: MatDialogRef<EmailChangeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    newmail = "";
    password = "";

  onNoClick(): void {
    this.dialogRef.close();
  }

}
