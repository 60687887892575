import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-request-reset-pw',
  templateUrl: './request-reset-pw.component.html',
  styleUrls: ['./request-reset-pw.component.scss']
})
export class RequestResetPWComponent {

  email: string = "";
  requesting: boolean = false;
  completed: boolean = false;

  constructor(private api: ApiService, private _snackBar: MatSnackBar) { }

  sendRequest() {
    this.requesting = true;

    this.api.requestResetLink(this.email).then((result) => {
      this.requesting = false;
      this.completed = true;
    }).catch((reason) => {
      this.requesting = false;
      console.log(reason);
      this._snackBar.open(reason.error.message, null, {duration: 2000});
    });

  }

  backToHome() {
    window.history.pushState({}, document.title, "/");
    location.pathname = "/";
  }
}
