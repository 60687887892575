<div id="changelog-content">
    <mat-card>
        <div id="changelog-title">
            Changelog
        </div>
        <div id="changelog-text">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let log of changelog">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Version: {{log.version}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li *ngFor="let change of log.changes">{{change}}</li>
                    </ul>
                </mat-expansion-panel>

            </mat-accordion>
        </div>
    </mat-card>
</div>