<div id="topBar">

  <div id="deckName" class="notmobile">
    {{cardDeck.name}}
  </div>

  <div id="deckCode">
    {{cardDeck.deckcode}}
  </div>

  <div id="buttonWrapper">
    <button *ngIf="!cardDeck.locked" mat-stroked-button class="notmobile saveBtn btn" [disabled]="canNotSave()" (click)="btnSaveClick(true)"
      matTooltip="This will add your deck to the moderation queue and publish it (if the deck is private it will not be visible to anyone except you and moderators).">Save & Publish</button>

    <button *ngIf="!cardDeck.locked" mat-icon-button class="mobile saveBtn btn" [disabled]="canNotSave()" (click)="btnSaveClick(true)" matTooltip="This will add your deck to the moderation queue and publish it (if the deck is private it will not be visible to anyone except you and moderators).">
      <mat-icon>save</mat-icon>
    </button>

    <button *ngIf="!cardDeck.locked" mat-stroked-button class="notmobile saveBtn btn" [disabled]="canNotSave()" (click)="btnSaveClick(false)"
      matTooltip="Saves your deck for later editing without needing to be moderated. DECK WILL NOT BE USABLE IN GAMES.">Save draft</button>

    <button *ngIf="!cardDeck.locked" mat-icon-button class="mobile saveBtn btn" [disabled]="canNotSave()" (click)="btnSaveClick(false)" matTooltip="Saves your deck for later editing without needing to be moderated. DECK WILL NOT BE USABLE IN GAMES.">
      <mat-icon class="material-icons-outlined">draft</mat-icon>
    </button>

    <button mat-stroked-button class="notmobile cancelBtn btn" (click)="btnCancelClick()"
      matTooltip="Close the deck editor without saving your changes">{{cardDeck.locked? "Close" : "Cancel"}}</button>

    <button mat-icon-button class="mobile cancelBtn btn" (click)="btnCancelClick()" matTooltip="Close the deck editor without saving your changes">
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>

    <button mat-stroked-button class="notmobile btn" (click)="btnDeleteClick()" matTooltip="Delete this deck. THIS IS NOT RECOVERABLE!">Delete</button>

    <button mat-icon-button class="mobile btn" (click)="btnDeleteClick()" matTooltip="Delete this deck. THIS IS NOT RECOVERABLE!">
      <mat-icon class="material-icons-outlined">delete</mat-icon>
    </button>

    <button mat-icon-button (mouseover)="showHelp = true" (mouseout)="showHelp = false">
      <mat-icon class="material-icons-outlined">help</mat-icon>
    </button>

    <button mat-icon-button [matMenuTriggerFor]="exportMenu" aria-label="Export Deck" matTooltip="Download this Deck in various JSON formats">
      <mat-icon class="material-icons-outlined">file_download</mat-icon>
    </button>

    <mat-menu #exportMenu="matMenu">
      <button mat-menu-item (click)="exportDeck('crcast')">
        <span>CRCast Format</span>
      </button>
      <button mat-menu-item (click)="exportDeck('allbadcards')">
        <span>All Bad Cards Custom Deck</span>
      </button>
      <button mat-menu-item (click)="exportDeck('pyxapp')">
        <span>PYX Android App</span>
      </button>
      <button mat-menu-item (click)="exportDeck('manydecks')">
        <span>Many Decks</span>
      </button>
    </mat-menu>
  </div>
</div>

<div id="page-grid">
  <!-- Settings Wrapper containing two columns of Settings-->
  <div id="settings-wrapper">
    <!-- First Settings Column with Name and Description-->
    <div class="settings" style="flex-grow: 1;">
      <mat-form-field class="NameForm">
        <mat-label>Name:</mat-label>
        <input matInput #name maxlength="25" placeholder="The Name of the CardDeck" [disabled]="cardDeck.locked" [(ngModel)]="cardDeck.name" (change)="saveDeck()">
        <mat-hint align="end">{{name.value.length}} / 25</mat-hint>
      </mat-form-field>

      <mat-form-field class="DescriptionForm">
        <mat-label>Description:</mat-label>
        <input matInput #desc maxlength="200" placeholder="Write a fancy Description!" [disabled]="cardDeck.locked" [(ngModel)]="cardDeck.description" (change)="saveDeck()">
        <mat-hint align="end">{{desc.value.length}} / 200</mat-hint>
      </mat-form-field>
    </div>

    <!-- Second Settings Column with Private, NSFW and Language-->
    <div class="settings">
      <mat-slide-toggle matTooltip="A private Deck is not listed on CRCast, but can be used normally" [disabled]="cardDeck.locked" [(ngModel)]="cardDeck.private" (change)="saveDeck()">Private</mat-slide-toggle><br/>

      <mat-slide-toggle matTooltip="NSFW decks may contain vulgar and offensive language" [disabled]="cardDeck.nsfw == 2 || cardDeck.locked" [(ngModel)]="bIsNSFW" (change)="saveDeck()">NSFW</mat-slide-toggle><br/>

      <mat-form-field matTooltip="Language of the deck is used as information and for filters" id="language-select">
        <mat-label>Language</mat-label>
        <mat-select [disabled]="cardDeck.locked" [(ngModel)]="cardDeck.language" (selectionChange)="saveDeck()">
          <mat-option *ngFor="let lang of languages()" [value]="lang">
            {{lang.displayName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Mod Notes and Help Overlay -->
  <div id="modnotes-wrapper">
    <div id="modnotes-title">{{showHelp? "Help" : "Mod Feedback"}}</div>
    <blockquote>
      <markdown *ngIf="!showHelp" [data]="cardDeck.moderationNotes"></markdown>
      <div *ngIf="showHelp">
        Use underscores "_" to mark "blank spots" on black cards.<br/>
        Images can be displayed on cards by using [img]link to image[/img].<br>
      </div>
    </blockquote>
  </div>

  <mat-tab-group id="cardTabs" class="mobile" dynamicHeight animationDuration="0ms" mat-stretch-tabs="true">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="cards-header">
          <div class="title">
            Black Cards
          </div>
          <div class="counter" [ngClass]="{'error': !limits.checkWhiteLimit(whiteCards().length)}">
            {{blackCards().length}}/{{limits.blacksLimit}}
          </div>
        </div>
      </ng-template>
      <div id="blackCards" class="cardWrapper">
        <div class="cardgrid">
          <blackcard *ngIf="!cardDeck.locked" [showEditButtons]="false" [newCard]="true" (addClicked)="addBlackCard($event)"></blackcard>
          <blackcard *ngFor="let bc of blackCards()" [blackCard]="bc" [changed]="isChangedBlack(bc.id)" [showEditButtons]="!cardDeck.locked" (saveClicked)="saveBlackCard($event)" (deleteClicked)="deleteBlackCard($event)"></blackcard>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="cards-header">
          <div class="title">
            White Cards
          </div>
          <div class="counter" [ngClass]="{'error': !limits.checkWhiteLimit(whiteCards().length)}">
            {{whiteCards().length}}/{{limits.whitesLimit}}
          </div>
        </div>
      </ng-template>
      <div id="whiteCards" class="cardWrapper">
        <div class="cardgrid">
          <whitecard *ngIf="!cardDeck.locked" [showEditButtons]="false" [newCard]="true" (addClicked)="addWhiteCard($event)"></whitecard>
          <whitecard *ngFor="let wc of whiteCards()" [whiteCard]="wc" [changed]="isChangedWhite(wc.id)" [showEditButtons]="!cardDeck.locked" (saveClicked)="saveWhiteCard($event)" (deleteClicked)="deleteWhiteCard($event)"></whitecard>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <!-- Black Cards -->
  <div id="blackCards" class="cardWrapper notmobile">
    <div class="cards-header">
      <div class="title">
        Black Cards
      </div>
      <div class="counter" [ngClass]="{'error': !limits.checkWhiteLimit(whiteCards().length)}">
        {{blackCards().length}}/{{limits.blacksLimit}}
      </div>
    </div>
    <div class="cardgrid">
      <blackcard *ngIf="!cardDeck.locked" [showEditButtons]="false" [newCard]="true" (addClicked)="addBlackCard($event)"></blackcard>
      <blackcard *ngFor="let bc of blackCards()" [blackCard]="bc" [changed]="isChangedBlack(bc.id)" [showEditButtons]="!cardDeck.locked" (saveClicked)="saveBlackCard($event)" (deleteClicked)="deleteBlackCard($event)"></blackcard>
    </div>
  </div>

  <div id="delimiter" class="notmobile">

  </div>

  <!-- White Cards -->
  <div id="whiteCards" class="cardWrapper notmobile">
    <div class="cards-header">
      <div class="title">
        White Cards
      </div>
      <div class="counter" [ngClass]="{'error': !limits.checkWhiteLimit(whiteCards().length)}">
        {{whiteCards().length}}/{{limits.whitesLimit}}
      </div>
    </div>
    <div class="cardgrid">
      <whitecard *ngIf="!cardDeck.locked" [showEditButtons]="false" [newCard]="true" (addClicked)="addWhiteCard($event)"></whitecard>
      <whitecard *ngFor="let wc of whiteCards()" [whiteCard]="wc" [changed]="isChangedWhite(wc.id)" [showEditButtons]="!cardDeck.locked" (saveClicked)="saveWhiteCard($event)" (deleteClicked)="deleteWhiteCard($event)"></whitecard>
    </div>
  </div>
</div>

<div *ngIf="loading" id="spinner-overlay">
  <mat-progress-spinner
    id="spinner"
    color="#2196f3"
    mode="indeterminate">
</mat-progress-spinner>
</div>