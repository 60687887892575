import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailChangeDialog } from '../account-profile/account-profile.component';
import { ApiService } from '../api.service';
import { Role, User } from '../Classes';
import { showErrorSnackbar } from '../utils';

@Component({
  selector: 'app-admin-userlist',
  templateUrl: './admin-userlist.component.html',
  styleUrls: ['./admin-userlist.component.scss']
})
export class AdminUserlistComponent implements OnInit {

  users: User[] = [];
  currentPage = 0;
  pageSize = 25;
  userCount: number = 0;
  loading: boolean = false;

  filtertext: string = "";
  sort: string = "none";
  isfilter: boolean = false;
  filterActive: number = 2;
  filterBanned: number = 2;
  filterRoles = [Role.Administrator.name, Role.Moderator.name, Role.Normal.name];

  Role = Role;

  constructor(private api: ApiService, public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  isLoggedIn() {
    return this.api.isLoggedIn();
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.onApiReady();
    });
  }

  onApiReady() {
    this.loadNew();
  }

  loadNew() {
    if (this.loading) return;

    this.loading = true;
    this.users = [];

    if (this.isfilter) {
      this.api.getAdminUsersSortFilter(this.currentPage, this.pageSize, this.sort, this.filtertext, this.filterActive, this.filterBanned, this.filterRoles.map(r => Role.parseRole(r))).then((data) => {
        this.users = data.users;
        this.userCount = data.count;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        showErrorSnackbar(err, this._snackBar);
      });
    } else {
      this.api.getAdminUsersSortFilter(this.currentPage, this.pageSize, this.sort, null, this.filterActive, this.filterBanned, this.filterRoles.map(r => Role.parseRole(r))).then((data) => {
        this.users = data.users;
        this.userCount = data.count;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        showErrorSnackbar(err, this._snackBar);
      });
    }
  }

  switchActive() {
    this.filterActive++;
    if (this.filterActive > 2) {
      this.filterActive = 0;
    }
    this.isfilter = true;
    this.reload();
  }

  switchBanned() {
    this.filterBanned++;
    if (this.filterBanned > 2) {
      this.filterBanned = 0;
    }
    this.isfilter = true;
    this.reload();
  }

  roleFilterChanged() {
    this.isfilter = true;
    this.reload();
  }

  setUserActivated(user: User) {
    let promise = null;
    if (user.activated) {
      promise = this.openResendActivationDialog();
    } else promise = new Promise((resolve, reject) => resolve(false));

    promise.then((resendActivation) => {
      this.api.adminSetUserActive(user.id, !user.activated, resendActivation).then((data) => {
        this.loadNew();
      }).catch((err) => {
        showErrorSnackbar(err, this._snackBar);
      });
    }).catch((reason) => {
      this._snackBar.open(reason, null, {duration: 2000});
    });
  }

  setUserBanned(user: User) {
    this.api.adminSetUserBanned(user.id, !user.banned).then((data) => {
      this.loadNew();
    }).catch((err) => {
      showErrorSnackbar(err, this._snackBar);
    });
  }

  setUserRole(user: User, role: Role) {
    this.api.adminSetUserRole(user.id, role).then((data) => {
      this.loadNew();
    }).catch((err) => {
      showErrorSnackbar(err, this._snackBar);
    });
  }

  setUserDeletion(user: User) {
    if (user.deletionQueued) {
      this.api.adminCancelUserDeletion(user.id).then((data) => {
        this.loadNew();
      }).catch((err) => {
        showErrorSnackbar(err, this._snackBar);
      });
    } else {
      this.api.adminQueueUserDeletion(user.id).then((data) => {
        this.loadNew();
      }).catch((err) => {
        showErrorSnackbar(err, this._snackBar);
      });
    }
  }

  switchPage(event) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  reload() {
    this.loadNew();
  }

  resort(event) {
    this.sort = event.value;
    this.reload();
  }

  applyFilters() {
    this.isfilter = true;
    this.currentPage = 0;
    this.reload();
  }

  clearFilters() {
    this.isfilter = false;
    this.filtertext = "";
    this.filterActive = 2;
    this.filterBanned = 2;
    this.filterRoles = [Role.Administrator.name, Role.Moderator.name, Role.Normal.name];
    this.currentPage = 0;
    this.reload();
  }

  openChangeEmailDialog(user: User) {
    const dialogRef = this.dialog.open(EmailChangeDialog, {
      data: {isAdmin: true, oldmail: user.email, user: user}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);

      if (result) {
        this.openResendActivationDialog().then((resendActivation) => {
          this.api.adminSetUserEmail(user.id, result.email, resendActivation).then((data) => {
            this.loadNew();
          }).catch((err) => {
            showErrorSnackbar(err, this._snackBar);
          });
        });
      }
    });
  }

  openChangePasswordDialog(user: User) {
    const dialogRef = this.dialog.open(PasswordChangeDialog, {
      data: {user: user}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);

      if (result) {
        this.api.adminSetUserPassword(user.id, result.password).then((data) => {
          this.loadNew();
        }).catch((err) => {
          showErrorSnackbar(err, this._snackBar);
        });
      }
    });
  }

  openResendActivationDialog(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ResendActivationDialog, {
        data: {}
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        console.log(result);
        resolve(result);
      });
    });
  }
}

@Component({
  selector: 'resend-activation-dialog',
  templateUrl: 'resend-activation-dialog.html',
})
export class ResendActivationDialog {

  constructor(
    public dialogRef: MatDialogRef<ResendActivationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'passwordchange-dialog',
  templateUrl: 'passwordchange-dialog.html',
})
export class PasswordChangeDialog {

  constructor(
    public dialogRef: MatDialogRef<PasswordChangeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    password = "";

  onNoClick(): void {
    this.dialogRef.close();
  }

}