<div class="whitecard">

    <div *ngIf="!newCard && !editingCard" class="whitecard-text">
        <div *ngIf="!isImage(whiteCard.text)">{{whiteCard.text}}</div>
        <img alt="White Card Image" *ngIf="isImage(whiteCard.text)" class="whitecard-image" src="{{getImageURL(whiteCard.text)}}"/>
    </div>

    <mat-form-field *ngIf="newCard || editingCard" appearance="standard" class="whitecard-textinput">
        <textarea matInput #message maxlength="150" placeholder="Epic White Card" [(ngModel)]="whiteCard.text"></textarea>
        <mat-hint align="end">{{message.value.length}} / 150</mat-hint>
    </mat-form-field>

    <div *ngIf="_isChanged()" class="changedMarker"></div>

    <div class="whitecard-menu" [style.background]="_stateBackground()" [matTooltip]="_getCardWarning(whiteCard.text)" [matTooltipDisabled]="_getCardWarning(whiteCard.text) == ''">
        <button *ngIf="_showEditButtons() && !editingCard" mat-icon-button aria-label="Delete" (click)="btnDeleteClick()" style="color: #d50000;">
            <mat-icon>delete</mat-icon>
        </button>

        <button *ngIf="_showEditButtons() && !editingCard" mat-icon-button aria-label="Edit" (click)="btnEditClick()">
            <mat-icon>edit</mat-icon>
        </button>

        <button *ngIf="newCard" mat-icon-button aria-label="Add" (click)="btnAddClick()" [disabled]="whiteCard.text.trim() == ''">
            <mat-icon>add</mat-icon>
        </button>

        <button *ngIf="editingCard" mat-icon-button aria-label="Save" (click)="btnSaveClick()" [disabled]="whiteCard.text.trim() == ''">
            <mat-icon>save</mat-icon>
        </button>

        <button *ngIf="editingCard" mat-icon-button aria-label="Cancel" (click)="btnCancelClick()">
            <mat-icon>cancel</mat-icon>
        </button>

        <button *ngIf="showModButtons" [style.background]="(accepted && accepted != undefined)? 'rgba(0,200,83, 0.3)' : 'transparent'" style="color: #00c853;" mat-icon-button aria-label="Accept" (click)="btnModClicked(true)">
            <mat-icon>done</mat-icon>
        </button>

        <button *ngIf="showModButtons" [style.background]="(!accepted && accepted != undefined)? 'rgba(213,0,0, 0.3)' : 'transparent'" style="color: #d50000;" mat-icon-button aria-label="Decline" (click)="btnModClicked(false)">
            <mat-icon>close</mat-icon>
        </button>

        <div *ngIf="_isUnpublished()" id="unpublished-text">
            Unpublished
        </div>
    </div>
</div>