import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CardDeck } from '../Classes';
import { ApiService } from '../api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'comp-carddeck-card',
  templateUrl: './carddeck-card.component.html',
  styleUrls: ['./carddeck-card.component.scss']
})
export class CarddeckCardComponent implements OnInit {
  @Input() cardDeck: CardDeck = new CardDeck();
  @Input() showDeckState: boolean;
  @Input() showCopyButtons: boolean;
  @Input() showFavButton: boolean;
  @Input() showModLock: boolean;
  @Input() isFavorite: boolean;
  @Input() isLoadingFav: boolean;
  @Input() hasCheckpoint: boolean;
  @Input() searchTerm: string = "";
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() favoriteClicked: EventEmitter<Promise<void>> = new EventEmitter<Promise<void>>();

  constructor(private api: ApiService, private _snackBar: MatSnackBar, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon('nsfw', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/nsfw-24px.svg"));
  }

  getStateColor() {
    return this.cardDeck.getStateColor();
  }

  getStateText() {
    return this.cardDeck.getStateText();
  }

  getBlacksCount() {
    return Math.max(this.cardDeck.blackCount, this.cardDeck.blacks.length);
  }

  getWhitesCount() {
    return Math.max(this.cardDeck.whiteCount, this.cardDeck.whites.length);
  }

  isLoggedIn() {
    return this.api.isLoggedIn();
  }

  ngOnInit(): void {
  }

  copyCode() {
    const el = document.createElement('textarea');
    el.value = this.cardDeck.deckcode;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this._snackBar.open("Successfully copied Deck Code to clipboard!", null, {duration: 1000});
  }

  copyURL() {
    const el = document.createElement('textarea');
    el.value = "https://api.crcast.cc/v1/" + this.cardDeck.deckcode;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this._snackBar.open("Successfully copied Deck URL to clipboard!", null, {duration: 1000});
  }

  click(event) {
    this.clicked.emit();
  }

  clickFav() {
    this.favoriteClicked.emit();
  }
}
