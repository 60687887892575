<h1 mat-dialog-title>Delete Account</h1>
<div mat-dialog-content>
    <p>
        Do you really want to delete your CRCast Account?
        <br/>
        All your created Decks will be deleted forever and cannot be recovered later!
        <br/>
        If you are sure you want to delete your Account please type the following text into the Verification Field.
        <br/>
        <code class="noselect" style="width: fit-content; margin-top: 8px; background-color: #1b1b1b; border-radius: 4px; color: white; padding: 6px; margin-bottom: 16px; display: block;">
            {{data.verification}}
        </code>
    </p>
    <mat-form-field appearance="outline">
        <mat-label>Enter Verification</mat-label>
        <input matInput [(ngModel)]="verification">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-flat-button [disabled]="verification != data.verification" [mat-dialog-close]="true" color="warn" cdkFocusInitial>Delete</button>
</div>