import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MarkdownModule } from 'ngx-markdown';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';

import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatRippleModule } from '@angular/material/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DecklistComponent } from './decklist/decklist.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { CheckpointLoadDialog, DeckcreatorComponent, DeckDeleteDialog } from './deckcreator/deckcreator.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { AccountComponent } from './account/account.component';
import { AccountProfileComponent, DeleteDialog, EmailChangeDialog } from './account-profile/account-profile.component';
import { AccountMyDecksComponent } from './account-my-decks/account-my-decks.component';
import { LegalComponent } from './legal/legal.component';
import { CarddeckCardComponent } from './carddeck-card/carddeck-card.component';
import { BlackcardComponent } from './blackcard/blackcard.component';
import { WhitecardComponent } from './whitecard/whitecard.component';
import { ApidocComponent } from './apidoc/apidoc.component';
import { DeckviewComponent } from './deckview/deckview.component';
import { ErrorDialog, ModLockDialog, ModviewComponent } from './modview/modview.component';
import { ModlistComponent } from './modlist/modlist.component';
import { ActivatorComponent } from './activator/activator.component';
import { CategorylistComponent } from './categorylist/categorylist.component';
import { JsonUploadDialogComponent } from './json-upload-dialog/json-upload-dialog.component';
import { LogindialogComponent } from './logindialog/logindialog.component';
import { RequestResetPWComponent } from './request-reset-pw/request-reset-pw.component';
import { ResetPWComponent } from './reset-pw/reset-pw.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { EmptystateComponent } from './emptystate/emptystate.component';
import { HighlightSearchPipe } from './highlight/highlight.pipe';
import { AuthInterceptor } from './auth.interceptor';
import { AdministrationComponent } from './administration/administration.component';
import { AuthGuardService } from './auth-guard.service';
import { AdminDecklistComponent } from './admin-decklist/admin-decklist.component';
import { AdminUserlistComponent, PasswordChangeDialog, ResendActivationDialog } from './admin-userlist/admin-userlist.component';
import { HelpComponent } from './help/help.component';
import { AccountMenuButtonComponent } from './accountMenuButton.component';
import { AdminEventlistComponent } from './admin-eventlist/admin-eventlist.component';
import { ChangelogComponent } from './changelog/changelog.component';
import { TextSanitizePipe } from './textsanitize.pipe';
import { AdminTasksComponent } from './admin-tasks/admin-tasks.component';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './route-reuse';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        DecklistComponent,
        ToolbarComponent,
        DeckcreatorComponent,
        RegisterComponent,
        AccountComponent,
        AccountProfileComponent,
        AccountMyDecksComponent,
        LegalComponent,
        CarddeckCardComponent,
        BlackcardComponent,
        WhitecardComponent,
        ApidocComponent,
        DeckviewComponent,
        ModviewComponent,
        ModlistComponent,
        ActivatorComponent,
        CategorylistComponent,
        JsonUploadDialogComponent,
        LogindialogComponent,
        RequestResetPWComponent,
        ResetPWComponent,
        NotfoundComponent,
        ErrorDialog,
        ModLockDialog,
        DeleteDialog,
        DeckDeleteDialog,
        EmailChangeDialog,
        EmptystateComponent,
        HighlightSearchPipe,
        AdministrationComponent,
        AdminDecklistComponent,
        AdminUserlistComponent,
        ResendActivationDialog,
        PasswordChangeDialog,
        CheckpointLoadDialog,
        HelpComponent,
        AccountMenuButtonComponent,
        AdminEventlistComponent,
        ChangelogComponent,
        TextSanitizePipe,
        AdminTasksComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MarkdownModule.forRoot(),
        HighlightModule,
        AppRoutingModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSnackBarModule,
        MatSelectModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatMenuModule,
        MatCardModule,
        ClipboardModule,
        MatRippleModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        AuthGuardService,
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
              fullLibraryLoader: () => import('highlight.js')
            }
        },
        {provide: RouteReuseStrategy, useClass: CustomReuseStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private iconReg: MatIconRegistry) {
        console.log('Registering icons');
        iconReg.setDefaultFontSetClass('material-symbols-outlined');
    }
}
