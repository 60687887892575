<h1 mat-dialog-title>Change Account Password</h1>
<div mat-dialog-content>
    <p>
        If you want to change the Password of the Account "{{data.user.username}}" please fill out the Form below.
    </p>
    <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Enter the new Password</mat-label>
        <input matInput type="password" [(ngModel)]="password">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-flat-button [disabled]="password == ''" [mat-dialog-close]="{password: password}" cdkFocusInitial>Change</button>
</div>