import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-logindialog',
  templateUrl: './logindialog.component.html',
  styleUrls: ['./logindialog.component.scss']
})
export class LogindialogComponent implements OnInit {

  logindata = {username: "", password: ""};

  constructor(public dialogRef: MatDialogRef<LogindialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.logindata.username = data.username;
     }

  ngOnInit(): void {
  }

  onCancelClick() {
    this.dialogRef.close();
  }

}
