<div id="title">
    Privacy and Legal Disclaimers
</div>
<div class="entry-title">
    1. Privacy
</div>
<div class="entry-content">
    1.1 We do NOT store your IP address or any other information that is not functionality related.<br>
	1.2 Your passwords are stored in an encrypted, unaccessible database.<br>
	1.3 We do not share any user information with 3RD parties.<br>
	1.4 You must not upload any content which is not properly licensed by you.<br>
</div>
<div class="entry-title">
    2. Copyright
</div>
<div class="entry-content">
    2.1 “Cards Against Humanity” and the CAH logos are trademarks of <a href="https://cardsagainsthumanity.com/" rel="noopener noreferrer" target="_blank">Cards Against Humanity, LLC.</a><br>
	2.2 Cards Against Humanity LLC is not affiliated with CLRTD, this website, or any of its contents.<br>
	2.3 We do not own the name or any of the rights to "Cards Against Humanity".<br>
</div>
<div class="entry-title">
    3. 3RD Party technologies
</div>
<div class="entry-content">
    TBD
</div>
<div class="entry-title">
    4. Acceptable use
</div>
<div class="entry-content">
    4.1 You must not:<br>
    a) use our website in any way or take any action that causes, or may cause, damage to the website or impairment of the performance, <br>
       availability or accessibility of the website;<br>
    b) use our website in any way that is unlawful, illegal, fraudulent or harmful, <br>
       or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity;<br>
    c) use data collected from our website for any direct marketing activity.<br>
</div>
<div id="other">
    We reserve the right to discontinue or alter any or all of our website services, <br>
    and to stop publishing our website, at any time in our sole discretion without notice or explanation.<br>
</div>
