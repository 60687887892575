import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { CardDeck, CREvent, Language, Limits, Role, TaskInfo, User } from './Classes';

import * as sha512 from 'js-sha512';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  AUTH_API_URL: string = environment.apiUrl
  DECK_API_URL: string = environment.splitports? environment.deckApiUrl : environment.apiUrl

  API_SELECTOR_ADMIN: string = "admin/";
  API_SELECTOR_DECKS: string = "decks/";

  API_SELECTOR_USER: string = "user/";
  API_SELECTOR_LOGIN: string = "login/";
  API_SELECTOR_REGISTER: string = "register/";
  API_SELECTOR_ACTIVATE: string = "activate/";

  limits: Limits = new Limits();
  user: User = null;

  onLogin: Promise<void> = null;

  constructor(public http: HttpClient, private cookieService: CookieService) {
    this.onLogin = new Promise<void>((resolve, reject) => {
      this.getLimits().then((limits) => {
        this.limits = limits;
      }).catch((reason) => {
        console.log("Error getting limits");
      });

      if (this.cookieService.check("token")) {
        this.getUserInfo().then((data) => {
          console.log("Got User Information");
          this.setUser(data);
          resolve();
        }).catch((reason) => {
          console.log("Error getting user information");
          this.setUser(null);
          cookieService.delete("token");
          resolve();
        });
      } else {
        console.log("No Token found!");
        this.setUser(null);
        resolve();
      }
    }).then(() => {
      this.onLogin = null;
    });
  }

  waitForAPI(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.onLogin != null) {
        this.onLogin.then(() => {
          return resolve();
        });
      } else {
        return resolve();
      }

    }).then(() => {
      this.onLogin = null;
    });
  }

  logout() {
    this.cookieService.delete('token');
    this.cookieService.delete('token', '/');
    this.cookieService.delete('token', '/', 'localhost');
    this.user = null;
  }

  isLoggedIn() {
    return this.user != null;
  }

  getUsername() {
    if (this.user == null) return "ERROR";
    return this.user.username;
  }

  setUser(user: User) {
    this.user = user;
  }

  rawGetRequest(url: string, params?: any) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(url, {params: params}).subscribe({next: (data: any) => {
        resolve(data);
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getLimits() {
    return new Promise<any>((resolve, reject) => {
      this.http.get(this.DECK_API_URL + "decks/limits").subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(new Limits(data));
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getVersion() {
    return new Promise<any>((resolve, reject) => {
      this.http.get(this.AUTH_API_URL + "version").subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getToken(userident: string, password: string) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(this.AUTH_API_URL + this.API_SELECTOR_USER + this.API_SELECTOR_LOGIN, {username: userident, password: sha512.sha512(password)}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          this.cookieService.set("token", data.token);
          resolve(data.token);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  registerUser(userident: string, password: string, email: string) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(this.AUTH_API_URL + this.API_SELECTOR_USER + this.API_SELECTOR_REGISTER, {username: userident, password: sha512.sha512(password), email: email}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          this.cookieService.set("token", data.token);
          resolve(data.token);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  activateMail(activationCode: string) {
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.AUTH_API_URL + this.API_SELECTOR_USER + "activate", {ac: activationCode}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          this.cookieService.set("token", data.token);
          this.getUserInfo();
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  resendActivationMail() {
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.AUTH_API_URL + this.API_SELECTOR_USER + "resendActivation", {}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  changeMail(password: string, email: string) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(this.AUTH_API_URL + this.API_SELECTOR_USER + "changeMail", {password: sha512.sha512(password), email: email}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          this.cookieService.set("token", data.token);
          this.getUserInfo();
          resolve(data.email);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  queueAccountDeletion() {
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.AUTH_API_URL + this.API_SELECTOR_USER + "delete", {}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  cancelAccountDeletion() {
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.AUTH_API_URL + this.API_SELECTOR_USER + "canceldelete", {}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  requestResetLink(email: string) {
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.AUTH_API_URL + this.API_SELECTOR_USER + "requestResetPW", {email: email}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  resetPassword(resetcode: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.AUTH_API_URL + this.API_SELECTOR_USER + "resetPW", {resetCode: resetcode, password: sha512.sha512(password)}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          this.cookieService.set("token", data.token);
          this.getUserInfo();
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getUserInfo() {
    return new Promise<User>((resolve, reject) => {
      this.http.get(this.AUTH_API_URL + this.API_SELECTOR_USER).subscribe({next: (data: any) => {
        if (data.error == 0) {
          var usr: User = new User(data.user);
          resolve(usr);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getUserDecks() {
    return new Promise<CardDeck[]>((resolve, reject) => {
      if (!this.isLoggedIn()) {
        reject(-1);
        return;
      }

      this.http.get(this.DECK_API_URL + this.API_SELECTOR_DECKS + this.API_SELECTOR_USER).subscribe({next: (data: any) => {
        if (data.error == 0) {
          var decks: CardDeck[] = [];
          for (var deckcode in data.decks) {
            decks.push(new CardDeck(data.decks[deckcode]));
          }
          resolve(decks);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getUserDeck(deckcode: string) {
    return new Promise<CardDeck>((resolve, reject) => {
      if (!this.isLoggedIn()) {
        reject(-1);
        return;
      }

      this.http.get(this.DECK_API_URL + this.API_SELECTOR_DECKS + this.API_SELECTOR_USER + deckcode).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(new CardDeck(data.deck));
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  adminGetUserDecks(userid: number) {
    return new Promise<CardDeck[]>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.get(this.DECK_API_URL + this.API_SELECTOR_ADMIN + this.API_SELECTOR_DECKS + this.API_SELECTOR_USER + userid).subscribe({next: (data: any) => {
        if (data.error == 0) {
          var decks: CardDeck[] = [];
          for (var deckcode in data.decks) {
            decks.push(new CardDeck(data.decks[deckcode]));
          }
          resolve(decks);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getModDecks() {
    return new Promise<CardDeck[]>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Moderator)) {
        reject(-1);
        return;
      }

      this.http.get(this.DECK_API_URL + this.API_SELECTOR_DECKS + "mod/").subscribe({next: (data: any) => {
        if (data.error == 0) {
          var decks: CardDeck[] = [];
          for (var deckcode in data.decks) {
            decks.push(new CardDeck(data.decks[deckcode]));
          }
          resolve(decks);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getModDeck(deckcode: string) {
    return new Promise<CardDeck>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Moderator)) {
        reject(-1);
        return;
      }

      this.http.get(this.DECK_API_URL + this.API_SELECTOR_DECKS + "mod/" + deckcode).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(new CardDeck(data.deck));
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getFavoriteDecks() {
    return new Promise<CardDeck[]>((resolve, reject) => {
      if (!this.isLoggedIn()) {
        reject(-1);
        return;
      }

      this.http.get(this.DECK_API_URL + this.API_SELECTOR_DECKS + "fav/").subscribe({next: (data: any) => {
        if (data.error == 0) {
          var decks: CardDeck[] = [];
          var codes: string[] = [];

          for (var i in data.decks) {
            codes.push(data.decks[i].deckcode);
            decks.push(new CardDeck(data.decks[i]));
          }

          this.user.favorites = codes;
          resolve(decks);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  addFavorite(deckcode: string) {
    return new Promise<string[]>((resolve, reject) => {
      if (!this.isLoggedIn()) {
        reject(-1);
        return;
      }

      this.http.post(this.DECK_API_URL + this.API_SELECTOR_DECKS + "fav/", {deckcode: deckcode}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          this.user.favorites = data.newFavs;
          resolve(data.newFavs);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  removeFavorite(deckcode: string) {
    return new Promise<string[]>((resolve, reject) => {
      if (!this.isLoggedIn()) {
        reject(-1);
        return;
      }

      this.http.delete(this.DECK_API_URL + this.API_SELECTOR_DECKS  + "fav/" + deckcode).subscribe({next: (data: any) => {
        if (data.error == 0) {
          this.user.favorites = data.newFavs;
          resolve(data.newFavs);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getCardDecks(page: number, amount: number) {
    return this.getCardDecksSortFilter(page, amount, null, null, null, null, null);
  }
  getCardDecksSort(page: number, amount: number, sort: string) {
    return this.getCardDecksSortFilter(page, amount, sort, null, null, null, null);
  }
  getCardDecksSortFilter(page: number, amount: number, sort: string, filter: string, sfw: boolean, favorites: boolean, language: string[]) {
    var urlstring = this.DECK_API_URL + this.API_SELECTOR_DECKS + page + "/" + amount + "/";
    var queryFilter = "?";
    if (sort != null) queryFilter += "sort=" + sort + "&";
    if (filter != null && filter != "") queryFilter += "text=" + filter + "&";
    if (sfw != null && sfw) queryFilter += "sfw=" + (sfw? "1" : "0") + "&";
    if (favorites != null && favorites) queryFilter += "favorites=" + (favorites? "1" : "0") + "&";
    if (language != null && language.length > 0) queryFilter += "lang=" + (language.join(",")) + "&";

    queryFilter = queryFilter.substr(0, queryFilter.length - 1);

    return new Promise<{decks: CardDeck[], count: number}>((resolve, reject) => {
      this.http.get(urlstring + queryFilter).subscribe({next: (data: any) => {
        if (data.error == 0) {
          var decks: CardDeck[] = [];
          for (var i in data.decks) {
            decks.push(new CardDeck(data.decks[i]));
          }
          resolve({decks: decks, count: data.count});
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getAdminCardDecks(page: number, amount: number) {
    return this.getAdminCardDecksSortFilter(page, amount, null, null, null, null);
  }
  getAdminCardDecksSort(page: number, amount: number, sort: string) {
    return this.getAdminCardDecksSortFilter(page, amount, sort, null, null, null);
  }
  getAdminCardDecksSortFilter(page: number, amount: number, sort: string, filter: string, sfw: boolean, language: string[]) {
    var urlstring = this.DECK_API_URL + this.API_SELECTOR_DECKS + this.API_SELECTOR_ADMIN + page + "/" + amount + "/";
    var queryFilter = "?";
    if (sort != null) queryFilter += "sort=" + sort + "&";
    if (filter != null && filter != "") queryFilter += "text=" + filter + "&";
    if (sfw != null && sfw) queryFilter += "sfw=" + (sfw? "1" : "0") + "&";
    if (language != null && language.length > 0) queryFilter += "lang=" + (language.join(",")) + "&";

    queryFilter = queryFilter.substr(0, queryFilter.length - 1);

    return new Promise<{decks: CardDeck[], count: number}>((resolve, reject) => {
      this.http.get(urlstring + queryFilter).subscribe({next: (data: any) => {
        if (data.error == 0) {
          var decks: CardDeck[] = [];
          for (var i in data.decks) {
            decks.push(new CardDeck(data.decks[i]));
          }
          resolve({decks: decks, count: data.count});
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getAdminUsers(page: number, amount: number) {
    return this.getAdminUsersSortFilter(page, amount, null, null, 2, 2, null);
  }
  getAdminUsersSort(page: number, amount: number, sort: string) {
    return this.getAdminUsersSortFilter(page, amount, sort, null, 2, 2, null);
  }
  getAdminUsersSortFilter(page: number, amount: number, sort: string, filter: string, active: number, banned: number, roles: Role[]) {
    var urlstring = this.AUTH_API_URL + "user/" + this.API_SELECTOR_ADMIN + page + "/" + amount + "/";
    var queryFilter = "?";
    if (sort != null) queryFilter += "sort=" + sort + "&";
    if (filter != null && filter != "") queryFilter += "text=" + filter + "&";
    if (active != null && active != 2) queryFilter += "active=" + active + "&";
    if (banned != null && banned != 2) queryFilter += "banned=" + banned + "&";
    if (roles != null && roles.length > 0) queryFilter += "roles=" + (roles.map(r => r.name).join(",")) + "&";

    queryFilter = queryFilter.substr(0, queryFilter.length - 1);

    return new Promise<{users: User[], count: number}>((resolve, reject) => {
      this.http.get(urlstring + queryFilter).subscribe({next: (data: any) => {
        if (data.error == 0) {
          var users: User[] = [];
          for (var i in data.users) {
            users.push(new User(data.users[i]));
          }
          resolve({users: users, count: data.count});
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getAdminEvents(page: number, amount: number) {
    return this.getAdminEventsFilter(page, amount, null, null);
  }
  getAdminEventsFilter(page: number, amount: number, filter: string, types: string[]) {
    var urlstring = this.AUTH_API_URL + "user/events/" + this.API_SELECTOR_ADMIN + page + "/" + amount + "/";
    var queryFilter = "?";
    if (filter != null && filter != "") queryFilter += "text=" + filter + "&";
    if (types != null && types.length > 0) queryFilter += "types=" + (types.join(",")) + "&";

    queryFilter = queryFilter.substr(0, queryFilter.length - 1);

    return new Promise<{events: CREvent[], count: number}>((resolve, reject) => {
      this.http.get(urlstring + queryFilter).subscribe({next: (data: any) => {
        if (data.error == 0) {
          var events: CREvent[] = [];
          for (var i in data.events) {
            events.push(new CREvent(data.events[i]));
          }
          resolve({events: events, count: data.count});
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  getCardDeck(deckcode: string) {
    return new Promise<CardDeck>((resolve, reject) => {
      this.http.get(this.DECK_API_URL + this.API_SELECTOR_DECKS + deckcode).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(new CardDeck(data.deck));
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  updateCardDeck(cardDeck: CardDeck, changeBlack, changeWhite, publish: boolean) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn()) {
        reject(-1);
        return;
      }

      var deck = cardDeck.serialize();
      deck["publish"] = publish;
      deck["changeBlacks"] = changeBlack;
      deck["changeWhites"] = changeWhite;

      this.http.put(this.DECK_API_URL + this.API_SELECTOR_DECKS + cardDeck.deckcode, deck).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data.code);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  moderateCardDeck(deckcode: string, notes: string, blacksMap: Map<number, boolean>, whitesMap: Map<number, boolean>, acceptCardDeck: boolean, nsfwstate: number) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Moderator)) {
        reject(-1);
        return;
      }

      var blacks = [];
      if (blacksMap == null) {
        blacks = null;
      } else {
        for (let black of blacksMap.keys()) {
          blacks.push({id: black, accept: blacksMap.get(black)});
        }
      }

      var whites = [];
      if (whitesMap == null) {
        whites = null;
      } else {
        for (let white of whitesMap.keys()) {
          whites.push({id: white, accept: whitesMap.get(white)});
        }
      }

      this.http.put(this.DECK_API_URL + this.API_SELECTOR_DECKS + "mod/" + deckcode, {notes: notes, blacks: blacks, whites: whites, deck: {accept: acceptCardDeck, nsfw: nsfwstate}}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data.code);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  deleteCardDeck(deckcode: string) {
    return new Promise<any>((resolve, reject) => {
      if (!this.isLoggedIn()) {
        reject(-1);
        return;
      }

      this.http.delete(this.DECK_API_URL + this.API_SELECTOR_DECKS + deckcode).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  newCardDeck(cardDeck: CardDeck, publish: boolean, isImport: boolean = false) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn()) {
        reject(-1);
        return;
      }

      var deck = cardDeck.serialize();
      deck["publish"] = publish;

      this.http.post(this.DECK_API_URL + this.API_SELECTOR_DECKS + (isImport? "?import=1" : ""), deck).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data.code);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

  // Administration

  adminSetUserActive(userid: number, active: boolean, resendActivation: boolean) {
    return new Promise<any>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.post(this.AUTH_API_URL + "user/" + this.API_SELECTOR_ADMIN + "active", {userid: userid, active: active, resendActivation: resendActivation}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }
  adminSetUserBanned(userid: number, banned: boolean) {
    return new Promise<any>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.post(this.AUTH_API_URL + "user/" + this.API_SELECTOR_ADMIN + "ban", {userid: userid, banned: banned}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }
  adminQueueUserDeletion(userid: number) {
    return new Promise<any>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.post(this.AUTH_API_URL + "user/" + this.API_SELECTOR_ADMIN + "delete", {userid: userid}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }
  adminCancelUserDeletion(userid: number) {
    return new Promise<any>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.post(this.AUTH_API_URL + "user/" + this.API_SELECTOR_ADMIN + "canceldelete", {userid: userid}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }
  adminSetDeckFeatured(deckcode: string, feature: boolean) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.post(this.DECK_API_URL  + this.API_SELECTOR_DECKS + this.API_SELECTOR_ADMIN + "featured", {deckcode: deckcode, feature: feature}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }
  adminSetDeckLocked(deckcode: string, lock: boolean) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.post(this.DECK_API_URL  + this.API_SELECTOR_DECKS + this.API_SELECTOR_ADMIN + "locked", {deckcode: deckcode, locked: lock}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }
  adminSetDeckNSFW(deckcode: string, nsfw: number) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.post(this.DECK_API_URL  + this.API_SELECTOR_DECKS + this.API_SELECTOR_ADMIN + "nsfw", {deckcode: deckcode, nsfw: nsfw}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }
  adminSetDeckLanguage(deckcode: string, language: Language) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.post(this.DECK_API_URL  + this.API_SELECTOR_DECKS + this.API_SELECTOR_ADMIN + "language", {deckcode: deckcode, language: language.langCode}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }
  adminSetUserEmail(userid: number, email: string, resendActivation: boolean) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.post(this.AUTH_API_URL + "user/" + this.API_SELECTOR_ADMIN + "email", {userid: userid, email: email, resendActivation: resendActivation}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }
  adminSetUserRole(userid: number, role: Role) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.post(this.AUTH_API_URL + "user/" + this.API_SELECTOR_ADMIN + "role", {userid: userid, role: role.name}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }
  adminSetUserPassword(userid: number, password: string) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.post(this.AUTH_API_URL + "user/" + this.API_SELECTOR_ADMIN + "password", {userid: userid, password: sha512.sha512(password)}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }
  adminGetTasks() {
    return new Promise<TaskInfo>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.get(this.AUTH_API_URL + "user/" + this.API_SELECTOR_ADMIN + "tasks").subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data as TaskInfo);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }
  adminStartTask(taskName: string) {
    return new Promise<any>((resolve, reject) => {
      if (!this.isLoggedIn() || !this.user.hasRole(Role.Administrator)) {
        reject(-1);
        return;
      }

      this.http.post(this.AUTH_API_URL + "user/" + this.API_SELECTOR_ADMIN + "tasks", {taskName: taskName}).subscribe({next: (data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      }, error: (err) => {
        reject(err);
      }});
    });
  }

}
