import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { ApiService } from './api.service';
import { Role } from './Classes';


@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
    constructor(private router:Router, private api: ApiService) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>|boolean {
        console.log("PARENT");

        console.log(route.url[0].path);

        return new Promise<boolean>((resolve, reject) => {
            this.api.waitForAPI().then(() => {
                if (route.url[0].path == "admin") {
                    if (!this.api.isLoggedIn() || !this.api.user.hasRole(Role.Administrator)) {

                        this.router.navigate([""]);
                        return resolve(false);
                    }
                } else if (route.url[0].path == "account") {
                    if (!this.api.isLoggedIn()) {

                        this.router.navigate([""]);
                        return resolve(false);
                    }
                }

                return resolve(true);
            });
        });
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>|boolean {
        console.log("CHILD");
        console.log(childRoute.url[0].path);

        return this.canActivateUrl(childRoute.url[0].path);
    }

    canActivateUrl(url: string): Promise<boolean>|boolean {
        return new Promise<boolean>((resolve, reject) => {
            this.api.waitForAPI().then(() => {
                if (url == "mod") {
                    if (!this.api.isLoggedIn() || !this.api.user.hasRole(Role.Moderator)) {

                        this.router.navigate([""]);
                        return resolve(false);
                    }
                }

                return resolve(true);
            });
        });
    }
}