<div style="padding-top: 64px;">
    <div id="activate-wrapper">
        <div id="activate-title">
            Account Activation
        </div>

        <div *ngIf="activating" id="spinner-overlay">
            <mat-progress-spinner
            id="spinner"
            color="#2196f3"
            mode="indeterminate">
            </mat-progress-spinner>
        </div>

        <div *ngIf="email != ''" id="activate-complete">
            <div id="activate-complete-text">
                You successfully activated the E-Mail:
            </div>
            <div id="activate-complete-email">{{email}}</div>

            <div id="activate-complete-text2">
                Now you can Create and Edit your custom Card Decks
            </div>

            <button id="activate-complete-button" mat-flat-button (click)="backToHome()">Back to Home</button>
        </div>

        <div *ngIf="error != ''" id="activate-complete">
            <div id="activate-complete-text">
                There has been an Error while activating your Account:
            </div>
            <div id="activate-complete-email">{{error}}</div>

            <div id="activate-complete-text2">
                You can contact us via <a href="https://twitter.com/sirjuicebag" target="_blank" rel="noopener noreferrer" class="activate-contactlink">Twitter</a>, <a href="https://discord.gg/85GBhxd" target="_blank" rel="noopener noreferrer" class="activate-contactlink">Discord</a> or <a href="https://www.reddit.com/r/CLRTD/" target="_blank" rel="noopener noreferrer" class="activate-contactlink">Reddit</a>
            </div>

            <button id="activate-complete-button" mat-flat-button (click)="backToHome()">Back to Home</button>
        </div>
    </div>
</div>