import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormControl, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service';
import { showErrorSnackbar } from '../utils';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  userident = new UntypedFormControl('', [Validators.required, Validators.minLength(6), this.validateUserInUse.bind(this), this.validateUsername.bind(this)]);
  password = new UntypedFormControl('', [Validators.required, Validators.minLength(6), this.validatePassword.bind(this)]);
  password2 = new UntypedFormControl('', [Validators.required, this.validateEqualPassword.bind(this)]);
  email = new UntypedFormControl('', [Validators.required, this.validateEmail, this.validateEmailInUse.bind(this)]);

  emailInUse = false;
  userInUse = false;

  requesting: boolean = false;
  registerComplete: boolean = false;

  registerform = this.fb.group({
    userident: this.userident,
    password: this.password,
    password2: this.password2,
    email: this.email
  });

  register() {
    this.emailInUse = false;
    this.userInUse = false;

    this.requesting = true;

    this.api.registerUser(this.registerform.value.userident, this.registerform.value.password, this.registerform.value.email).then((result) => {
      this.api.getUserInfo().then((data) => {
        this.api.setUser(data);
        this.registerComplete = true;
        this.requesting = false;
      }).catch((reason) => {
        this.requesting = false;
        console.log(reason);
      });
    }).catch((err) => {
      this.requesting = false;

      if (err.status == 409) {
        this.userInUse = true;
        this.userident.updateValueAndValidity();
        this.emailInUse = true;
        this.email.updateValueAndValidity();
      }

      console.log(err);
      showErrorSnackbar(err, this._snackBar);
    });
  }

  backToHome() {
    location.pathname = "/";
  }

  validateUsername(control: AbstractControl): {[key: string]: any} | null  {
    let match = control.value.match(/[A-Za-z0-9_\-]+/i);
    if (match == null || match[0] != control.value) {
      return {'invalidUsername': true};
    }
    return null;
  }

  validatePassword(control: AbstractControl): {[key: string]: any} | null  {
    let match = control.value.match(/[A-Za-z0-9_\-!§$%&?^+*#\':;,.]+/i);
    if (match == null || match[0] != control.value) {
      return {'invalidPassword': true};
    }
    return null;
  }

  validateEmail(control: AbstractControl): {[key: string]: any} | null  {
    let match = control.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i);
    if (match == null || match[0] != control.value) {
      return {'email': true};
    }
    return null;
  }

  validateEqualPassword(control: AbstractControl): {[key: string]: any} | null  {
    if (control.value != this.password.value) {
      return {'passwordNotEqual': true};
    }
    return null;
  }

  validateUserInUse(control: AbstractControl): {[key: string]: any} | null  {
    if (this.userInUse) {
      return { 'userInUse': true };
    }
    return null;
  }

  validateEmailInUse(control: AbstractControl): {[key: string]: any} | null  {
    if (this.emailInUse) {
      return { 'emailInUse': true };
    }
    return null;
  }

  getErrorMessage(element) {
    if (element == "email") {
      if (this.email.hasError('required')) {
        return 'You must enter a value';
      } else if (this.email.hasError('email')) {
        return 'Not a valid email';
      } else if (this.email.hasError('emailInUse')) {
        return 'EMail Address already in use';
      }
    } else if (element == "userident") {
      if (this.userident.hasError('required')) {
        return 'You must enter a value';
      } else if (this.userident.hasError('minlength')) {
        return 'Username too short';
      } else if (this.userident.hasError('userInUse')) {
        return 'Username already in use';
      } else if (this.userident.hasError('invalidUsername')) {
        return 'Invalid Characters';
      }
    } else if (element == "password") {
      if (this.password.hasError('required')) {
        return 'You must enter a value';
      } else if (this.password.hasError('minlength')) {
        return 'Password too short';
      } else if (this.password.hasError('invalidPassword')) {
        return 'Invalid Characters';
      }
    } else if (element == "password2") {
      if (this.password2.hasError('required')) {
        return 'You must enter a value';
      } else if (this.password2.hasError('passwordNotEqual')) {
        return 'Passwords do not match';
      }
    }
  }

  constructor(private api: ApiService,
    private fb: UntypedFormBuilder, private _snackBar: MatSnackBar) { }

}
