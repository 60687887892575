import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WhiteCard } from '../Classes';

@Component({
  selector: 'whitecard',
  templateUrl: './whitecard.component.html',
  styleUrls: ['./whitecard.component.scss']
})
export class WhitecardComponent implements OnInit {
  @Input() whiteCard: WhiteCard;
  @Input() newCard: boolean;
  @Input() showEditButtons: boolean;
  @Input() showModButtons: boolean;
  @Input() accepted: string;
  @Input() changed: boolean;

  @Output() saveClicked: EventEmitter<object> = new EventEmitter<object>();
  @Output() deleteClicked: EventEmitter<WhiteCard> = new EventEmitter<WhiteCard>();
  @Output() addClicked: EventEmitter<WhiteCard> = new EventEmitter<WhiteCard>();
  @Output() modClicked: EventEmitter<object> = new EventEmitter<object>();

  constructor() { }

  /**
   * TODO:
   * - Change booleans to boolean value type
   */

  _showEditButtons() {
    return this.showEditButtons;
  }

  _showNewButton() {
    return this.showEditButtons;
  }

  _showModButton() {
    return this.showModButtons;
  }

  _isRejected() {
    return this.whiteCard.state == 0 && !this.hasBeenEdited;
  }

  _isUnpublished() {
    return this.whiteCard.state == 3 && !this.hasBeenEdited;
  }

  _isChanged() {
    return this.changed;
  }

  _getCardWarning(text: string) {
    if (text.includes("[img]") && !text.includes("[/img]")) return "Image Tag not closed properly";
    else if (!text.includes("[img]") && text.includes("[/img]")) return "Image Tag not started properly";
    else if (text.includes("[img]") && text.includes("[/img]")) {
      if (!text.startsWith("[img]")) return "Text before Image Tag will be discarded!";
      else if (!text.endsWith("[/img]")) return "Text after Image Tag will be discarded!";
      else if (text.match(/\[img\](.*)\[\/img\]/)[1].includes(" ")) return "Image URL cannot contain spaces";
    }
    return "";
  }

  _stateBackground() {

    var warningState = this._getCardWarning(this.whiteCard.text);
    var warningBG = "repeating-linear-gradient(-55deg, #ff980070, #ff980070 10px, #3330 10px, #3330 20px)";

    var background = "";

    if (this.whiteCard.state == 0 && !this.hasBeenEdited) {
      background = "rgba(213,0,0, 0.3)";
    } else if (this.whiteCard.state == 3 && !this.hasBeenEdited) {
      background = "rgba(41,98,255, 0.3)";
    }

    if (warningState != "") {
      background += ((background != "")? ", " : "") + warningBG;
    }

    return background;
  }

  isImage(text: string) {
    let includesTags = text.includes("[img]") && text.includes("[/img]");
    if (!includesTags) return false;
    let link: string = text.match(/\[img\](.*)\[\/img\]/)[1];
    return !link.includes(" ");
  }

  getImageURL(text) {
    let link: string = text.match(/\[img\](.*)\[\/img\]/)[1];
    return link;
  }

  editingCard: boolean = false;
  originalCard: WhiteCard = null;
  hasBeenEdited: boolean = false;

  ngOnInit(): void {
    if (this.newCard) {
      this.whiteCard = new WhiteCard();
      this.whiteCard.id = -1;
    }
  }

  btnEditClick() {
    this.originalCard = new WhiteCard(JSON.parse(JSON.stringify(this.whiteCard)));
    this.editingCard = true;
  }

  btnSaveClick() {
    if (this.originalCard.text != this.whiteCard.text) this.hasBeenEdited = true;
    this.saveClicked.emit({original: this.originalCard, new: this.whiteCard});
    this.originalCard = null;
    this.editingCard = false;
  }

  btnCancelClick() {
    this.whiteCard = new WhiteCard(JSON.parse(JSON.stringify(this.originalCard)));
    this.originalCard = null;
    this.editingCard = false;
  }

  btnDeleteClick() {
    this.deleteClicked.emit(this.whiteCard);
  }

  btnAddClick() {
    if (this.whiteCard.text.trim() == "") return;
    this.addClicked.emit(this.whiteCard);
    this.whiteCard = new WhiteCard();
  }

  btnModClicked(accepted) {
    this.modClicked.emit({ id: this.whiteCard.id, accepted: accepted });
  }

}
