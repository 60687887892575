import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { CardDeck } from '../Classes';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-deckview',
  templateUrl: './deckview.component.html',
  styleUrls: ['./deckview.component.scss']
})
export class DeckviewComponent implements OnInit {
  cardDeck: CardDeck = new CardDeck({});
  loading: boolean = true;
  loadingFav: boolean = true;

  constructor(private api: ApiService, private location: Location, private route: ActivatedRoute, public dialog: MatDialog, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private _snackBar: MatSnackBar) {
    this.matIconRegistry.addSvgIcon('nsfw', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/nsfw-24px.svg"));
  }

  isLoggedIn() {
    return this.api.isLoggedIn();
  }

  isFavorite() {
    return this.api.isLoggedIn() && this.api.user.favorites.indexOf(this.cardDeck.deckcode) != -1;
  }

  clickFav() {
    this.loadingFav = true;
    if (this.isFavorite()) {
      this.api.removeFavorite(this.cardDeck.deckcode).then((data) => {
        this._snackBar.open("Successfully removed " + this.cardDeck.deckcode + " from your Favorites", null, {duration: 2000});
        this.loadingFav = false;
      }).catch((err) => {
        this._snackBar.open("There has been an Error removing " + this.cardDeck.deckcode + " from your Favorites", null, {duration: 2000});
        this.loadingFav = false;
      });
    } else {
      this.api.addFavorite(this.cardDeck.deckcode).then((data) => {
        this._snackBar.open("Successfully added " + this.cardDeck.deckcode + " to your Favorites", null, {duration: 2000});
        this.loadingFav = false;
      }).catch((err) => {
        this._snackBar.open("There has been an Error adding " + this.cardDeck.deckcode + " to your Favorites", null, {duration: 2000});
        this.loadingFav = false;
      });
    }
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.onApiReady();
    });
  }

  onApiReady() {
    let deckcode = this.route.snapshot.paramMap.get('deckcode');
    console.log(deckcode);

    this.api.getCardDeck(deckcode).then((data) => {
      this.cardDeck = data;
      this.loading = false;
    }).catch((err) => {
      const dialogRef = this.dialog.open(ErrorDialog, {
        width: '250px',
        data: err
      });

      dialogRef.afterClosed().subscribe(result => {
        this.btnCloseClick();
      });
    });

    this.api.getFavoriteDecks().then((data) => {
      this.loadingFav = false;
    }).catch((err) => {
      this.loadingFav = false;
    });
  }

  btnCloseClick() {
    this.location.back()
  }

}

@Component({
  selector: 'error-dialog',
  templateUrl: 'errordialog.html',
})
export class ErrorDialog {

  constructor(
    public dialogRef: MatDialogRef<ErrorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}